import React, { Component } from 'react'
import { Container } from '@mui/material'
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <NavMenu instance={this.props.instance} />
                <Container style={{ maxWidth: 'inherit', paddingLeft: 5, paddingRight: 5 }}>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
