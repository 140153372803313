const initialState = {
    userinfo: { userid: 0, loginid: '', employeeid: '', emailid: '', firstname: '', lastname: '', role: '', token: '' },
    unreadcount: { count: 0 },
    downloadreport: { userid: 0, reportid: 0, message: '', date: '' }
}

const appReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case "userinfo_login":
            {
                newState.userinfo = action.userinfo;
                return newState;
            }
        case "userinfo_logout":
            {
                newState.userinfo = initialState;
                return newState;
            }
        case "unread_count":
            {
                newState.unreadcount = action.unreadcount;
                return newState;
            }
        case "download_report":
            {
                newState.downloadreport = action.downloadreport;
                return newState;
            }
        default:
            return newState;
    }
}

export default appReducer;