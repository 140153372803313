
import React from 'react';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { TimeDiffInHHMMSS } from '../Utilities/Date';

const Timer = (props) => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [counter, setCounter] = useState(0);

    const [result, setResult] = useState('');

    const started = props.started;
    useEffect(() => {
        const interval = setInterval(() => getTime(started), 1000);
        return () => clearInterval(interval);
    }, []);

    const getTime = () => {
        var now = new Date();
        var aa = TimeDiffInHHMMSS(now, started) 
        setResult(aa)
    };

    return (
        <div style={{ marginLeft: 5 }}>{result}</div>
    );
};

export default Timer;

