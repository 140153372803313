import React, { useEffect, useState, useRef } from 'react';
import { Card, Paper, InputBase, IconButton, Menu, MenuItem, ListItemIcon, CardContent, CardHeader, Divider, Grid, Box, Avatar, Typography, Tooltip, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import utc from "dayjs/plugin/utc";
import { Search, Close, HistoryToggleOff, ManageHistory, List, Download, MoreHoriz, Share, InsightsOutlined } from '@mui/icons-material';
import { CustomAlert } from '../Utilities/CustomAlert'
import { FirstLetters, IsNullOrEmptyOrUndefined, ValidateInput, IsIOS, EncryptText } from '../Utilities/String';
import { ReportHistory } from './ReportHistory';
import { ReportSettings } from './ReportSettings';
import { ReportInputParams } from './ReportInputParams';
import { AddMinusDays } from '../Utilities/Date';
import { BASE_URL } from '../Utilities/APIURLs';
import { UserInsight } from './UserInsight';
import { useSelector, useDispatch } from 'react-redux'
import { GetJSON } from '../Utilities/APICall';
import { ReportAccess } from '../UserManagement/ReportAccess';
import * as signalR from '@microsoft/signalr';


dayjs.extend(utc);

const COLORS = ['#9F0000', '#001E51', '#027D00', '#0075DA', '#32105C', '#5C2893', '#B600A0', '#AA0000', '#095157', '#008272']

export function Home(props) {
    const userinfo = useSelector((state) => state.userinfo);
    const dispatch = useDispatch();
    const userId = userinfo.userid;
    const employeeId = userinfo.employeeid;
    const loginId = userinfo.loginid;


    const dateformat = 'DD MMM YY HH:mm:ss';
    const [reports, setReports] = useState([]);
    const [reportSearch, setReportSearch] = useState([]);
    const [loading, setLoading] = React.useState(false);

    const [role, setRole] = React.useState('');
    const [selectedReportId, setSelectedReportId] = React.useState(0);
    const [selectedReportName, setSelectedReportName] = React.useState('');
    const [selectedRefresh, setSelectedRefresh] = React.useState('');


    const [settingsReportId, setSettingsReportId] = React.useState(0);
    const [settingsReportName, setSettingsReportName] = React.useState('');
    const [settingsReportType, setSettingsReportType] = React.useState('');
    const [settingsReportFormat, setSettingsReportFormat] = useState('');

    const [parameters, setParameters] = useState([]);

    const [eventName, setEventName] = useState('Insight');
    const [reportInfo, setReportInfo] = useState({ ReportId: '', ReportName: '', ReportFormat: '', DownloadFormat: '', UserId: '', LoginId: '', EmployeeId: '' });
    const [anchorElHome, setAnchorElHome] = useState(null);
    const openHomeMenu = Boolean(anchorElHome);

    const [searchtext, setSearchtext] = useState('');
    const dataFetchedRef = useRef(false);
    const completionMessage = useRef(false);
    const IsRptColRefreshed = useRef(false);
    const enckey = 'abcdefghijklmnoj='



    useEffect(() => {
        if (userId > 0 && dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            setLoading(true)
            getAssignedReport();
            getUnreadCount();

            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    console.log('Notification permission granted')
                }
            });
            IsRptColRefreshed.current = true

        }
    }, [userId]);

    useEffect(() => {
        if (userId > 0 && completionMessage.current === false) {
            completionMessage.current = true;

            const connection = new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Debug)
                .withUrl("https://webapp-accelerator-timer.azurewebsites.net/api/", { headers: { "x-application-user-id": userId.toString() } })
                .withAutomaticReconnect()
                .build();

            connection.start()
                .then(() => {
                    console.log('Connection started')
                })
                .catch(err => {
                    console.log('Error while starting connection: ' + err)
                });

            connection.on('myMessage', (receivedMessage) => {
                console.log('message received');
                var msg = receivedMessage.split(':');
                if (msg[0] === userId.toString()) {
                    dispatch({ type: "download_report", downloadreport: { userid: msg[0], reportid: msg[1], message: msg[2], date: new Date().toISOString() } });
                    CustomAlert(msg[2], 'info');
                    showNotification('Accelerator Report', msg[2]);
                }
            });

        }
    }, [userId]);

    const getUnreadCount = () => {

        var url = BASE_URL + 'UserReport/GetUnreadCount';
        GetJSON(url, props.instance)
            .then(data => {
                dispatch({ type: "unread_count", unreadcount: { count: data } });
            })
            .catch((e) => {
                CustomAlert(e.message, 'error');
            });
    }
    /** API Calls */
    const getAssignedReport = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const queryReportId = queryParams.get("ReportId");

        var url = BASE_URL + 'Access/AssignedReport';
        GetJSON(url, props.instance).then(data => {
            setLoading(false)

            data.reports.result.forEach(item => {
                var st = (IsNullOrEmptyOrUndefined(item.started) === false) ? 'Executed:' + dayjs(item.started).utc('z').local().format(dateformat) : ''
                item.executed = st
            });
            setReports(data.reports.result);
            setReportSearch(data.reports.result);

            data.params.result.forEach(item => {
                switch (item.dataType) {
                    case 'String':
                    case 'Number':
                        item.dataValue = item.defaultValue;
                        break;
                    case 'Yes/No':
                        if (IsNullOrEmptyOrUndefined(item.defaultValue)) item.dataValue = false;
                        else item.dataValue = (item.defaultValue === 'Yes') ? true : false;
                        break;
                    case 'Choice':
                        if (item.multiSelect) {
                            item.dataValue = [item.defaultValue];
                        } else {
                            item.dataValue = item.defaultValue;
                        }
                        break;
                    case 'Date':
                    case 'DateTime':
                        if (IsNullOrEmptyOrUndefined(item.defaultValue) === false) {
                            var dv = eval(item.defaultValue)
                            item.dataValue = new Date(dv).toISOString()
                        }
                        else {
                            item.dataValue = new Date().toISOString()
                        }
                        break;
                    case 'Lookup':
                        if (item.multiSelect) {
                            item.dataValue = [];
                        }
                        break;
                }
            });
            setParameters(data.params.result);

            var rid = localStorage.getItem('QSReportId');
            if (IsNullOrEmptyOrUndefined(queryReportId) === false) {
                handleRedirectToReport(queryReportId, data.reports.result, data.params.result)
                localStorage.removeItem('QSReportId');
            }
            else if (rid !== false) {
                localStorage.removeItem('QSReportId');
                handleRedirectToReport(rid, data.reports.result, data.params.result)
            }
        })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    /** API Calls End */

    const showNotification = (title, body) => {

        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        }
        else if (IsIOS === true) {
            //Check iOS device
            alert("The device is iOs, This does not support desktop notification");

        } else if (Notification.permission === "granted") {
            const notification = new Notification(title, { body: body });

        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    const notification = new Notification("Hi there!");
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
    }
    const handleRedirectToReport = (ReportId, dReports, dParams) => {

        var IsReportExist = dReports.filter((o) => o.id === Number(ReportId));
        if (IsReportExist.length <= 0) {
            return false
        }

        setEventName('Download');
        setAnchorElHome(null);

        var rpt = IsReportExist[0]
        var rptObject = { ReportId: rpt.id, ReportName: rpt.name, ReportFormat: rpt.reportFormat, DownloadFormat: rpt.downloadFormat, LoginId: loginId, UserId: userId, EmployeeId: employeeId, ParamInfo: [] };

        sessionStorage.removeItem('LookupIds')
        if (rpt.reportType === 'Schedule') {
            setReportInfo(rptObject)
        }
        else {
            var _param = dParams.filter((o) => o.reportId === Number(rpt.id));
            if (_param.length > 0) {
                rptObject.ParamInfo = _param;
                setReportInfo(rptObject)
            }
        }
    }

    let clrCounter = 0;
    const setColors = () => {
        if (COLORS.length === clrCounter)
            clrCounter = 0;
        else
            clrCounter++;

        return COLORS[clrCounter];
    }

    const columns = [
        { field: 'id', headerName: 'Id' },
        {
            field: 'Dummy', headerName: '', width: 45, minWidth: 45, editable: false, sortable: false, align: 'center',
            renderCell: (cell) => {
                return (
                    <>
                        <Avatar sx={{ marginTop: '7px', width: 35, height: 35, bgcolor: setColors }} variant="rounded">
                            <Typography style={{ fontSize: 12 }}>{FirstLetters(cell.row.name)}</Typography>
                        </Avatar>
                    </>
                );
            },
        },
        {
            field: 'name', headerName: '', editable: false, flex: 1,
            renderCell: (cell) => {
                return (
                    <>
                        <CardHeader title={<Typography style={{ fontSize: 14 }} >{cell.row.name}</Typography>} subheader={<Typography style={{ fontSize: 12 }} color="text.secondary">{'Published: ' + dayjs(cell.row.published).utc('z').local().format(dateformat)}</Typography>} style={{ padding: 5 }} />
                    </>
                );
            },
        },
        {
            field: 'edit', headerName: '', align: 'center', width: 60, disableClickEventBubbling: true,
            renderCell: (cell) => {
                return (
                    <IconButton size='small' name='actionmenu' id='actionmunu' aria-label="Action" onClick={(e) => handleActionMenu(e, cell.row.id)}><MoreHoriz sx={{ fontSize: 22 }} color='primary' /></IconButton>
                );
            },
        },
    ];
    const handleActionMenu = (Event, ReportId) => {
        Event.ignore = true;
        setAnchorElHome(Event.currentTarget);
        var rpt = reports.filter((o) => o.id === Number(ReportId))[0];
        setSelectedReportId(rpt.id);
        setSelectedReportName(rpt.name)
        setRole(rpt.role);
    }
    const handleAction = (EventName) => {
        setEventName(EventName);
        setAnchorElHome(null);

        var rpt = reports.filter((o) => o.id === Number(selectedReportId))[0];
        var rptObject = { ReportId: rpt.id, ReportName: rpt.name, ReportFormat: rpt.reportFormat, DownloadFormat: rpt.downloadFormat, LoginId: loginId, UserId: userId, EmployeeId: employeeId, ParamInfo: [] };
        if (EventName === 'Settings') {
            setSettingsReportId(rpt.id)
            setSettingsReportType(rpt.reportType)
            setSettingsReportName(rpt.name)
            setSettingsReportFormat(rpt.reportFormat)
        }
        else if (EventName === 'History') {
            setReportInfo(rptObject)
            setSelectedRefresh(new Date());
        }
        else if (EventName === 'Share') {
            setReportInfo(rptObject)
            setSelectedRefresh(new Date());
        }
        else if (EventName === 'Download') {
            sessionStorage.removeItem('LookupIds')
            if (rpt.reportType === 'Schedule') {
                setReportInfo(rptObject)
            }
            else {
                var _param = parameters.filter((o) => o.reportId === Number(rpt.id));
                if (_param.length > 0) {
                    rptObject.ParamInfo = _param;
                    setReportInfo(rptObject)
                }
            }
        }
    }
    const handleChange = (e) => {
        setSearchtext(e.target.value);

        if (IsNullOrEmptyOrUndefined(e.target.value)) {
            setReportSearch(reports);
        }
        else {
            if (ValidateInput(e.target.value)) {
                handleSearch(e.target.value);
            }
        }
    }
    const handleSearch = (searchValue) => {
        if (IsNullOrEmptyOrUndefined(searchValue))
            setReportSearch(reports);
        var matches = reports.filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase()));
        setReportSearch(matches);
    }
    const handleSearchClear = () => {
        setSearchtext('');
        setReportSearch(reports);
    }
    const handleReportRowClicked = (rows, event) => {

        setEventName('Download');
        sessionStorage.removeItem('LookupIds')
        var rpt = reports.filter((o) => o.id === Number(rows.id))[0];
        var rptObject = { ReportId: rpt.id, ReportName: rpt.name, ReportFormat: rpt.reportFormat, DownloadFormat: rpt.downloadFormat, LoginId: loginId, UserId: userId, EmployeeId: employeeId, ParamInfo: [] };

        if (rpt.reportType === 'Schedule') {
            setReportInfo(rptObject)
        }
        else {
            var _param = parameters.filter((o) => o.reportId === Number(rpt.id));
            if (_param.length > 0) {
                rptObject.ParamInfo = _param;
                setReportInfo(rptObject)
            }
        }
    }
    return (
        <>
            <Grid container spacing={1} columns={{ xs: 3.5, sm: 8, md: 12 }}>
                <Grid item xs={3.5} >
                    <Card variant="outlined" style={{ marginTop: 10, height: window.innerHeight - 80 }} >
                        <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title='Report List' titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}><List /></Avatar>} action={<Tooltip title={'Insight'}><Button variant="outlined" size='small' style={{ width: 40, minWidth: 40, height: 30 }} onClick={() => setEventName('Insight')}><InsightsOutlined color='primary' /></Button></Tooltip>} />
                        <CardContent style={{ padding: 0 }}>
                            <div style={{ justifyContent: 'center', display: 'flex', textAlign: 'center', width: '100%', marginTop: 5, marginBottom: 5 }}>
                                <Paper elevation={0} sx={{ display: 'flex', alignItems: 'center', width: '95%', border: '1px solid silver' }}>
                                    <Search fontSize='small' sx={{ paddingLeft: 0.5, paddingRight: 0.5 }} color="disabled" />
                                    <InputBase sx={{ flex: 1 }} autoFocus value={searchtext} onChange={handleChange} placeholder="Search report..." inputProps={{ maxLength: 25, 'aria-label': 'Search report...' }} />
                                    <IconButton size="small" onClick={handleSearchClear}><Close fontSize="inherit" sx={{ paddingLeft: 0.5, paddingRight: 0.5, color: 'gray' }} /></IconButton>
                                </Paper>
                            </div>
                            <Divider />
                            <Box sx={{ height: window.innerHeight - 170, width: '100%' }}>
                                <DataGrid disableColumnMenu rowHeight={50} columnHeaderHeight={0} rows={reportSearch} columns={columns} loading={loading}
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 100 }, },
                                        columns: { columnVisibilityModel: { id: false }, },
                                    }}
                                    pageSizeOptions={[100]} hideFooterSelectedRowCount={true}
                                    sx={{ border: 0, '& .MuiDataGrid-row:hover': { backgroundColor: '#ccdcff', cursor: 'pointer' }, '& .MuiDataGrid-cell:hover': { color: 'black', }, fontSize: 13, '& .MuiDataGrid-iconSeparator, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .Mui-selected': { backgroundColor: 'green' }, '& .MuiDataGrid-footerContainer': {  border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" }, '& .MuiDataGrid-withBorderColor': { borderColor: 'white' } }}
                                    onRowClick={(param, event) => {
                                        if (!event.ignore) handleReportRowClicked(param.row, event);
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </Card >
                </Grid>
                <Grid item xs={8.5} style={{ marginTop: '10px', marginBottom: '10px' }}>
                    {eventName === 'Download' && <ReportInputParams ReportInfo={reportInfo} onRefresh={() => getAssignedReport()} instance={props.instance} />}
                    {eventName === 'Settings' && <ReportSettings UserId={userId} ReportId={settingsReportId} ReportName={settingsReportName} ReportType={settingsReportType} ReportFormat={settingsReportFormat} instance={props.instance} />}

                    {eventName === 'History' && <ReportHistory ReportInfo={reportInfo} Refresh={selectedRefresh} instance={props.instance} />}
                    {eventName === 'Insight' && <UserInsight UserId={userId} instance={props.instance} />}
                    {eventName === 'Share' && <ReportAccess AccessInfo={reportInfo} instance={props.instance} ShowGrantAll={false} />}
                </Grid>
            </Grid >
            <Menu elevation={0} sx={{ fontSize: 13, '& .MuiPaper-root': { border: '1px solid silver' } }} anchorEl={anchorElHome} open={openHomeMenu} onClose={(o) => setAnchorElHome(null)}>
                <MenuItem style={{ fontSize: 13 }} onClick={() => handleAction('Download')}><ListItemIcon><Download fontSize='small' color='primary' /></ListItemIcon>Execute</MenuItem>
                <MenuItem style={{ fontSize: 13 }} onClick={() => handleAction('Settings')}><ListItemIcon><ManageHistory fontSize='small' color='primary' /></ListItemIcon>Schedule</MenuItem>
                <MenuItem style={{ fontSize: 13 }} onClick={() => handleAction('History')}><ListItemIcon><HistoryToggleOff fontSize='small' color='primary' /></ListItemIcon>History</MenuItem>
                {(IsNullOrEmptyOrUndefined(role) === false && (role === 'Owner' || userinfo.role === 'Developer')) && <MenuItem style={{ fontSize: 13 }} onClick={() => handleAction('Share')}><ListItemIcon><Share fontSize='small' color='primary' /></ListItemIcon>Share</MenuItem>}
            </Menu >
        </>
    );

}
