import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { FormatFileSize, IsNullOrEmptyOrUndefined, EncryptText } from '../Utilities/String';
import { CustomAlert } from '../Utilities/CustomAlert'
import { Grid, Tooltip, Card, CardHeader, Avatar, Box, IconButton, CircularProgress, Alert, Button, Typography } from '@mui/material';
import { AssignmentTurnedIn, Close, DeleteOutline, Refresh, Report, TimerOutlined, WatchLater } from '@mui/icons-material';
import { BASE_URL } from '../Utilities/APIURLs';
import { GetJSON, GetBLOB } from '../Utilities/APICall';
import { useSelector, useDispatch } from 'react-redux'
import { TimeDiffInHHMMSS } from '../Utilities/Date';
import Timer from '../Components/Timer';

export function UserAssignReport(props) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isMobile = screenWidth <= 768;

    const userinfo = useSelector((state) => state.userinfo);
    const downloadreport = useSelector((state) => state.downloadreport);
    const dispatch = useDispatch();
    const dateformat = 'DD MMM YY HH:mm:ss';
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);

    useEffect(() => {
        if (props.UserId > 0) {
            getUserReport(props.UserId);
        }
    }, [props.UserId]);


    /** API Calls */
    const getUserReport = (UserId) => {
        setLoading(true)

        var url = BASE_URL + 'Access/GetByUserId?UserId=' + UserId
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                setReports(data)
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const downloadFile = (UserId, ReportId) => {
        setDownloading(true)

        var url = BASE_URL + 'UserReport/DownloadReport?UserId=' + UserId + '&ReportId=' + ReportId
        GetBLOB(url, props.instance)
            .then((data) => {
                setDownloading(false)
                if (IsNullOrEmptyOrUndefined(data.message) === false) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    var item = reports.filter((o) => o.reportId === ReportId);
                    if (item.length > 0 && item[0].readStatus === false) {
                        item[0].readStatus = true;
                        var reportcount = reports.filter((o) => o.readStatus === false).length
                        dispatch({ type: "unread_count", unreadcount: { count: reportcount } });
                    }
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = url;
                    let ext = ''
                    if (data.type === 'text/csv') ext = '.csv'
                    else ext = '.xlsx'
                    let fileName = 'Accelerator Report' + ext;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }
            })
            .catch((e) => {
                setDownloading(false)
                CustomAlert(e.message, 'error');
            });
    }
    /** API Calls End */

    const reportColumns = [
        { field: 'id', headerName: 'Id', width: 90 },
        { field: 'role', headerName: 'Role', width: 100 },
        { field: 'reportName', headerName: 'Name', flex: 1 },
        {
            field: 'edit', headerName: '', width: 60,
            renderCell: (cell) => {
                return (
                    <>
                        <Tooltip title='Unassigned'><IconButton size='small' aria-label="unassigned" onClick={() => handleDeleteUser(props.UserId, cell.row.reportId)}><DeleteOutline sx={{ fontSize: 22 }} color='error' /></IconButton></Tooltip>
                    </>
                );
            },
        },

    ];
    const handleRefresh = () => {
        getUserReport();
    }
    const deleteReportUser = (UserId, ReportId) => {
        setLoading(true)
        var url = BASE_URL + 'Access/Delete?UserId=' + UserId + '&ReportId=' + ReportId;
        GetJSON(url, props.instance)
            .then((data) => {
                setLoading(false)
                getUserReport(props.UserId);
                CustomAlert('User has unassigned from the report', 'success')
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const handleDeleteUser = (UserId, ReportId) => {
        if (window.confirm("Are you sure you want to unassign the selected report?") === true) {
            deleteReportUser(UserId, ReportId);
        }
    }
    return (
        <>
            <div style={{ padding: 0 }}>
                <Card variant="outlined">
                    <CardHeader style={{ padding: 8 }} title={'Assigned Report: ' + props.UserName} titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}><AssignmentTurnedIn /></Avatar>} action={<><IconButton size='small' disabled={(loading || downloading)} variant="outlined" aria-label="Download" onClick={handleRefresh} style={{ display: 'none' }}><Refresh /></IconButton><IconButton size='small' onClick={() => props.onClose(new Date().toISOString())}><Close color='error' /></IconButton></>} />
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid rowHeight={33} columnHeaderHeight={30} rows={reports} columns={reportColumns} disableColumnMenu loading={loading}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10, }, },
                                columns: { columnVisibilityModel: { id: false, reportId: false, timer: !isMobile, created: !isMobile, sizeinbyte: !isMobile }, },
                            }}
                            pageSizeOptions={[10]} disableRowSelectionOnClick
                            sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                        />
                    </Box>
                </Card>
            </div>
        </>
    );

}
