import React, { useEffect, useState, useRef } from 'react';
import { CustomAlert } from '../Utilities/CustomAlert'
import { CircularProgress, Box, Grid, Typography, CardContent, LinearProgress, Avatar, Card, CardHeader, Divider, Tooltip } from '@mui/material';
import { Insights, ErrorOutline, TaskAltOutlined, AccountCircle, DataUsage, Person, Delete } from '@mui/icons-material';
import { BASE_URL } from '../Utilities/APIURLs';
import { GetJSON } from '../Utilities/APICall';
// import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import dayjs from 'dayjs';
import { LocalTimeZone } from '../Utilities/Date';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux'
import { FirstLetters, IsNullOrEmptyOrUndefined } from '../Utilities/String';
import { orange } from '@mui/material/colors';

export function AdminInsight(props) {

    const downloadreport = useSelector((state) => state.downloadreport);
    const dateformat = 'DD MMM YY HH:mm:ss';
    const [reportType, setReportType] = useState({});
    const [successRate, setSuccessRate] = useState({});
    const [publishCount, setPublishCount] = useState({});
    const [user, setUser] = useState({});
    const [history, setHistory] = useState({});
    const [historyLastMonth, setHistoryLastMonth] = useState({});
    const [xLabels, setXLabels] = useState([]);
    const [xData, setXData] = useState([]);

    const [schedules, setSchedules] = useState([]);
    const [transaction, setTransaction] = useState([]);

    const [loading, setLoading] = useState(false);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            getInsight()
            getSchedule()
            getHistory()
        }
    }, []);

    useEffect(() => {
        if (IsNullOrEmptyOrUndefined(downloadreport.date) === false) {
            getHistory()
            downloadreport.date = ''
        }
    }, [downloadreport.date]);

    /** API Calls */
    const getInsight = () => {
        setLoading(true)
        var tm = LocalTimeZone();
        var url = BASE_URL + 'Insight/GetByAdmin?Timezone=' + tm;
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                setXLabels(data.historyLastMonthInsight.historyByDate.map(k => k.label))
                setXData(data.historyLastMonthInsight.historyByDate.map(k => k.value))
                setUser(data.userInsight)
                setHistory(data.historyInsight)
                setHistoryLastMonth(data.historyLastMonthInsight)

                var type = data.reportInsight.filter((o) => o.category === 'ReportType')
                if (type.length > 0) {
                    var arrsch = type.filter((o) => o.type === 'Schedule')
                    var sch = arrsch.length > 0 ? arrsch[0].count : 0
                    var arrint = type.filter((o) => o.type === 'Interactive')
                    var int = arrint.length > 0 ? arrint[0].count : 0
                    var per = Number(int) * 100 / (Number(int) + Number(sch))
                    setReportType({ Schedule: sch, Interactive: int, Percent: per })
                }
                var pb = data.reportInsight.filter((o) => o.category === 'Publish')
                if (pb.length > 0) {
                    var arrpub = pb.filter((o) => o.type === 'Published')
                    var pub = arrpub.length > 0 ? arrpub[0].count : 0
                    var arrunb = pb.filter((o) => o.type === 'Unpublished')
                    var unb = arrunb.length > 0 ? arrunb[0].count : 0
                    var per = Number(pub) * 100 / (Number(pub) + Number(unb))
                    setPublishCount({ Published: pub, Unpublished: unb, Percent: per })
                }
                var sr = data.reportInsight.filter((o) => o.category === 'SuccessRate')
                if (sr.length > 0) {
                    var arrsuc = sr.filter((o) => o.type === 'success')
                    var suc = arrsuc.length > 0 ? arrsuc[0].count : 0
                    var arrerr = sr.filter((o) => o.type !== 'success')
                    var err = arrerr.length > 0 ? arrerr.map(i => i.count).reduce((a, b) => Number(a) + Number(b)) : 0
                    var per = Number(suc) * 100 / (Number(suc) + Number(err))
                    setSuccessRate({ Success: suc, Error: err, Percent: per })
                }

            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const getSchedule = () => {
        setLoading(true)
        var url = BASE_URL + 'ReportSetting/GetAllRecurrence';
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                setSchedules(data)
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const getHistory = () => {
        setLoading(true)
        var url = BASE_URL + 'History/Get'
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                setTransaction(data)
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    /** API Calls End */
    const handleDeleteSchedule = (Id, UserId, ReportId) => {
        if (window.confirm("Are you sure you want to delete the schedule?") === true) {

            setLoading(true)
            var url = BASE_URL + 'ReportSetting/DeleteRecurrence?Id=' + Id + '&UserId=' + UserId + '&ReportId=' + ReportId;
            GetJSON(url, props.instance)
                .then(data => {
                    setLoading(false)
                    if (data.message === 'success') {
                        getSchedule()
                        CustomAlert('Schedule has been deleted', 'success');
                    }
                })
                .catch((e) => {
                    setLoading(false)
                    CustomAlert(e.message, 'error');
                });
        }
    }
    const schCol = [
        { field: 'id', headerName: 'Id', width: 90 },
        {
            field: 'frequency', headerName: '', width: 30, minWidth: 30, editable: false, align: 'left',
            renderCell: (cell) => {
                return (<><Avatar sx={{ marginTop: .2, width: 20, height: 20, bgcolor: 'orange' }}><Typography style={{ fontSize: 13 }}>{cell.row.frequency.substring(0, 1)}</Typography></Avatar></>);
            },
        },
        {
            field: 'userInput', headerName: '', editable: false, width: 100,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 2 }}>{cell.row.userinput}</Typography></>);
            },
        },
        {
            field: 'reportName', headerName: '', flex: 1,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 2 }}>{cell.row.reportName}</Typography></>);
            },
        },
        {
            field: 'userName', headerName: '', width: 30, minWidth: 30,
            renderCell: (cell) => {
                return (<><Tooltip title={cell.row.userName}><Avatar sx={{ marginTop: .2, width: 20, height: 20, bgcolor: 'purple' }}><Typography style={{ fontSize: 10 }}>{FirstLetters(cell.row.userName)}</Typography></Avatar></Tooltip></>);
            },
        },
        {
            field: 'delete', headerName: '', width: 40, minWidth: 40,
            renderCell: (cell) => {
                return (<div style={{ cursor: 'pointer' }} onClick={() => handleDeleteSchedule(cell.row.id, cell.row.userId, cell.row.reportId)}><Delete color='error' /></div>);
            },
        },
    ];
    const schHis = [
        { field: 'id', headerName: 'Id', width: 90 },
        {
            field: 'status', headerName: '', width: 40, minWidth: 40, editable: false, align: 'center',
            renderCell: (cell) => {
                return (<div style={{ marginTop: '3px' }}>{cell.row.status.toLowerCase() === 'success' ? <TaskAltOutlined fontSize="small" color="success" /> : cell.row.status.toLowerCase() === 'inprogress' ? <CircularProgress size={17} /> : <ErrorOutline fontSize="small" color="error" />}</div>);
            },
        },
        {
            field: 'frequency', headerName: '', width: 80, editable: false, align: 'left',
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 2 }}>{cell.row.frequency}</Typography></>);
            },
        },
        {
            field: 'started', headerName: '', editable: false, width: 130,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 2 }}>{dayjs(cell.row.started).utc('z').local().format(dateformat)}</Typography></>);
            },
        },
        {
            field: 'reportName', headerName: 'Name', editable: false, flex: 1,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 2 }}>{cell.row.reportName}</Typography></>);
            },
        },

    ];

    return (
        <>
            <Card variant="outlined" style={{ height: window.innerHeight - 80 }} >
                <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title="Insights" titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}> <Insights fontSize='20' /></Avatar>} />
                <CardContent>
                    <Grid container spacing={1.1} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={4}>
                            <Typography>Interactive Vs Schedule</Typography>
                            <Grid container spacing={1.1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                                <Grid item xs={12}>
                                    <LinearProgress variant="determinate" value={reportType.Percent || 0} color="primary" sx={{ height: 20, borderRadius: 1 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1.1} style={{ marginBottom: 20 }}>
                                <Grid item xs={6} style={{ justifyContent: 'left', textAlign: 'left', paddingLeft: 10 }}>
                                    <Typography>{reportType.Interactive || 0}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ justifyContent: 'right', textAlign: 'right', paddingRight: 2 }}>
                                    <Typography>{reportType.Schedule || 0}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Published Vs Unpublished</Typography>
                            <Grid container spacing={1.1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                                <Grid item xs={12}>
                                    <LinearProgress variant="determinate" value={publishCount.Percent || 0} color="secondary" sx={{ height: 20, borderRadius: 1 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1.1} style={{ marginBottom: 20 }}>
                                <Grid item xs={6} style={{ justifyContent: 'left', textAlign: 'left', paddingLeft: 10 }}>
                                    <Typography>{publishCount.Published || 0}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ justifyContent: 'right', textAlign: 'right', paddingRight: 2 }}>
                                    <Typography>{publishCount.Unpublished || 0}</Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Success Vs Failure</Typography>
                            <Grid container spacing={1.1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                                <Grid item xs={12}>
                                    <LinearProgress variant="determinate" value={successRate.Percent || 0} color="success" sx={{ height: 20, borderRadius: 1 }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} style={{ marginBottom: 20 }}>
                                <Grid item xs={6} style={{ justifyContent: 'left', textAlign: 'left', paddingLeft: 10 }}>
                                    <Typography>{successRate.Success || 0}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ justifyContent: 'right', textAlign: 'right', paddingRight: 2 }}>
                                    <Typography>{successRate.Error || 0}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1.1} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={4}>
                            <Card variant="outlined" style={{ backgroundColor: '#fdecce', border: 0 }}>
                                <CardContent style={{ paddingBottom: 2, textAlign: 'center', justifyContent: 'center' }}>
                                    <AccountCircle sx={{ fontSize: 40, color: '#ffae1f' }} />
                                    <Typography sx={{ fontSize: 14, color: '#ffae1f' }}>Application Users</Typography>
                                </CardContent>
                                <CardContent style={{ paddingTop: 2, paddingBottom: 2, textAlign: 'center', justifyContent: 'center' }}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: 14, color: '#ffae1f' }}>Active Users</Typography>
                                            <Typography variant='h6' sx={{ color: '#ffae1f' }}>{user.activeUser || 0}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: 14, color: '#ffae1f' }}>Deactive Users</Typography>
                                            <Typography variant='h6' sx={{ color: '#ffae1f' }}>{user.deactiveUser || 0}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card variant="outlined" style={{ backgroundColor: '#ccfff5', border: 0 }}>
                                <CardContent style={{ paddingBottom: 2, textAlign: 'center', justifyContent: 'center' }}>
                                    <DataUsage sx={{ fontSize: 40, color: '#13deb9' }} />
                                    <Typography sx={{ fontSize: 14, color: '#13deb9' }}>Total Transactions</Typography>
                                </CardContent>
                                <CardContent style={{ paddingTop: 2, paddingBottom: 2, textAlign: 'center', justifyContent: 'center' }}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: 14, color: '#13deb9' }}>Manual</Typography>
                                            <Typography variant='h6' sx={{ color: '#13deb9' }}>{history.manualHistory || 0}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: 14, color: '#13deb9' }}>Scheduled</Typography>
                                            <Typography variant='h6' sx={{ color: '#13deb9' }}>{history.scheduleHistory || 0}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card variant="outlined" style={{ backgroundColor: '#ecf2ff', border: 0 }}>
                                <CardContent style={{ paddingBottom: 2, textAlign: 'center', justifyContent: 'center' }}>
                                    <DataUsage sx={{ fontSize: 40, color: '#673ab7' }} />
                                    <Typography sx={{ fontSize: 14, color: '#673ab7' }}>Transactions(Last 15 days)</Typography>
                                </CardContent>
                                <CardContent style={{ paddingTop: 2, paddingBottom: 2, textAlign: 'center', justifyContent: 'center' }}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: 14, color: '#673ab7' }}>Manual</Typography>
                                            <Typography variant='h6' sx={{ color: '#673ab7' }}>{historyLastMonth.manualHistory || 0}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontSize: 14, color: '#673ab7' }}>Scheduled</Typography>
                                            <Typography variant='h6' sx={{ color: '#673ab7' }}>{historyLastMonth.scheduleHistory || 0}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ marginTop: 5 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={6}>
                            {/* <div style={{ height: 300, width: "100%" }}>
                                <LineChart
                                    series={[{ data: xData, label: 'Execution count', area: true, showMark: false, color: '#91beee' }]}
                                    xAxis={[{ scaleType: 'point', data: xLabels }]}
                                    sx={{ [`& .${lineElementClasses.root}`]: { display: 'none', }, }}
                                    margin={{ left: 30, right: 25 }}
                                />
                            </div> */}
                            <Card variant='outlined' style={{ width: '100%' }}>
                                <CardHeader title={<Typography style={{ fontSize: 13 }}>Scheduled Report</Typography>} style={{ padding: 4, paddingLeft: 10 }} />
                                <Divider />
                                <Box sx={{ height: 280, width: '100%' }}>
                                    <DataGrid rowHeight={24} columnHeaderHeight={0} rows={schedules} columns={schCol} disableColumnMenu loading={loading}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 100, }, },
                                            columns: { columnVisibilityModel: { id: false }, },
                                        }}
                                        pageSizeOptions={[100]} disableRowSelectionOnClick
                                        sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                                    />
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card variant='outlined' style={{ width: '100%' }}>
                                <CardHeader title={<Typography style={{ fontSize: 13 }}>Recent Transaction</Typography>} style={{ padding: 4, paddingLeft: 10 }} />
                                <Divider />
                                <Box sx={{ height: 280, width: '100%' }}>
                                    <DataGrid rowHeight={24} columnHeaderHeight={0} rows={transaction} columns={schHis} disableColumnMenu loading={loading}
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 100, }, },
                                            columns: { columnVisibilityModel: { id: false }, },
                                        }}
                                        pageSizeOptions={[100]} disableRowSelectionOnClick
                                        sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': {  border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                                    />
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
        </>
    );

}
