import React, { useState } from 'react';

import { Schedule, Assessment, Person, ViewColumn, Speed, Logout, QueryBuilderOutlined, HomeOutlined, ViewColumnOutlined, AccountCircleOutlined, PeopleAltOutlined, NotificationsOutlined } from '@mui/icons-material';
import { Badge, AppBar, Drawer, Toolbar, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, IconButton, Typography, Dialog, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { IsNullOrEmptyOrUndefined } from './Utilities/String';
import { UserReport } from './UserManagement/UserReport';
import './App.css';

export function NavMenu(props) {
    const userinfo = useSelector((state) => state.userinfo);
    const UnreadReport = useSelector((state) => state.unreadcount);
    const [open, setOpen] = React.useState(false);
    const [openDownload, setOpenDownload] = React.useState(false);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isMobile = screenWidth <= 768;


    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    const handleLogout = () => {
        props.instance.logoutRedirect({ postLogoutRedirectUri: "/", });
    };
    const handleCloseDialog = () => {
        setOpenDownload(false);
    }
    const handleOpenDialog = () => {
        setOpenDownload(true);
    }
    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/Home">
                        <ListItemIcon>
                            <Schedule color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={'Assigned Report'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/ReportMaster">
                        <ListItemIcon>
                            <Assessment color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={'Report Definition'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/ReportLookup">
                        <ListItemIcon>
                            <ViewColumn color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={'Lookup Definition'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/UserMaster">
                        <ListItemIcon>
                            <Person color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={'User Management'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={'LogOut'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
        </Box>
    );
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="static" elevation={0} sx={{ backgroundColor: '#F6F8FA' }}>
                    <Toolbar style={{ minHeight: 55 }}>
                        <Speed color='primary' fontSize="small" />
                        <Link to="/Home" style={{ textDecoration: 'none' }}><Typography style={{ paddingLeft: 5 }} variant="h6" component="div">Accelerator</Typography></Link>
                        <div style={{ flexGrow: 1 }}></div>

                        {
                            (userinfo.role === 'Developer') &&
                            <>
                                <div style={{ marginRight: '19%' }}></div>
                                <Link to="/Home" style={{ textDecoration: 'none' }}><Tooltip title={'Home'}><HomeOutlined color='primary' /></Tooltip></Link>

                                <div style={{ marginRight: 20 }}></div>
                                <Link to="/ReportMaster" style={{ textDecoration: 'none' }}><Tooltip title={'Build'}><QueryBuilderOutlined color='primary' /></Tooltip></Link>

                                <div style={{ marginRight: 20 }}></div>
                                <Link to="/ReportLookup" style={{ textDecoration: 'none' }}><Tooltip title={'Lookup'}><ViewColumnOutlined color='primary' /></Tooltip></Link>

                                <div style={{ marginRight: 20 }}></div>
                                <Link to="/UserMaster" style={{ textDecoration: 'none' }}><Tooltip title={'Users'}><PeopleAltOutlined color='primary' /></Tooltip></Link>
                            </>
                        }
                        {
                            IsNullOrEmptyOrUndefined(userinfo.firstname) === false &&
                            <>
                                <div style={{ marginRight: 20 }}></div>
                                <Link onClick={handleOpenDialog} style={{ textDecoration: 'none' }}><Tooltip title={'Report'}><Badge badgeContent={UnreadReport.count} color="error"><NotificationsOutlined color='primary' /></Badge></Tooltip></Link>

                                {
                                    isMobile === false &&
                                    <>
                                        <div style={{ marginRight: 20 }}></div>
                                        <AccountCircleOutlined fontSize='small' style={{ color: 'gray' }} />
                                        <Typography style={{ paddingLeft: 6, color: 'black', fontSize: 14 }}>{IsNullOrEmptyOrUndefined(userinfo.firstname) ? '' : userinfo.firstname + ' ' + userinfo.lastname}</Typography>
                                    </>
                                }
                                <div style={{ marginRight: 20 }}></div>
                                <Tooltip title={'Logout'}><IconButton size='small' aria-label="logout" onClick={handleLogout}><Logout color='primary' fontSize='small' /></IconButton></Tooltip>
                            </>
                        }
                    </Toolbar>

                    <Drawer open={open} onClose={toggleDrawer(false)} anchor={'right'}>
                        {DrawerList}
                    </Drawer>
                </AppBar>
            </Box><Divider />


            <Dialog fullWidth={true} maxWidth={'md'} onClose={handleCloseDialog} open={openDownload} disableEscapeKeyDown={true}>
                <UserReport instance={props.instance} onClose={handleCloseDialog} />
            </Dialog>
        </>
    );
}