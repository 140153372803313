import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { TitleCase, IsNullOrEmptyOrUndefined, ValidateInput } from '../Utilities/String';
import { CustomAlert } from '../Utilities/CustomAlert'
import { CircularProgress, Typography, Alert, Box, IconButton, Dialog, FormControl, InputLabel, Select, MenuItem, TextField, Button, Card, CardContent, CardHeader, Divider, Grid, Tooltip, Collapse, FormGroup, FormControlLabel, Switch, Avatar, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Close, Done, List, Addchart,  DeleteOutlined, Save, HighlightOff, PlayArrow } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { BASE_URL } from '../Utilities/APIURLs';
import { useSelector } from 'react-redux'
import { GetJSON, PostJSON } from '../Utilities/APICall';

export function ReportLookup(props) {
    const userinfo = useSelector((state) => state.userinfo);
    const LoginId = userinfo.loginid;

    const dateformat = 'DD MMM YY HH:mm:ss';
    const [lookups, setLookups] = useState([]);
    const [lookupName, setLookupName] = useState('');
    const [connectedLookupId, setConnectedLookupId] = useState(0);
    const [connectedLookupColumn, setConnectedLookupColumn] = useState('');

    const [lookupDescription, setLookupDescription] = useState('');
    const [lookupDatasource, setLookupDatasource] = useState('');
    const [lookupConnectionString, setLookupConnectionString] = React.useState('');
    const [lookupScript, setLookupScript] = useState('');
    const [isPublish, setIsPublish] = useState(false);
    const [isAddAll, setIsAddAll] = useState(false);

    const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [landing, setLanding] = React.useState(false);
    const [previewLoading, setPreviewLoading] = React.useState(false);

    const [isEdit, setIsEdit] = React.useState(false);
    const [selectedLookupId, setSelectedLookupId] = React.useState(0);
    const [selectedReportName, setSelectedReportName] = React.useState('');
    const [queryMessage, setQueryMessage] = React.useState('');
    const [queryRecords, setQueryRecords] = React.useState([]);
    const [queryStatusCode, setQueryStatusCode] = React.useState('info');
    const [queryTimeToExecute, setQueryTimeToExecute] = React.useState('');
    const [eventName, setEventName] = React.useState('NewLookup');
    const [restMethod, setRestMethod] = React.useState('GET');
    const [restUrl, setRestUrl] = React.useState('');


    const Sources = [{ 'name': 'Azure SQL Database', 'id': 'azuresqldatabase' }, { 'name': 'MS SQL Sqlserver', 'id': 'mssqlserver' }, { 'name': 'MySQL', 'id': 'mysql' }];

    const dRestMethod = ['GET', 'POST', 'PUT'];
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            setLanding(true)
            getDefinition()
        }
    }, []);

    /** API Calls */
    const getDefinition = () => {
        var url = BASE_URL + 'Lookup/Get'
        GetJSON(url, props.instance)
            .then(data => {
                setLanding(false)
                setLookups(data);
            })
            .catch((e) => {
                setLanding(false)
                CustomAlert(e, 'error');
            });
    }
    const getById = (Id) => {
        setLoading(true)
        var url = BASE_URL + 'Lookup/GetById?Id=' + Id
        GetJSON(url, props.instance)
            .then(report => {
                setLoading(false)
                setLookupName(report.name);
                setLookupDescription(report.description);
                setLookupDatasource(report.dataSource);
                setLookupConnectionString(report.connectionString);
                setLookupScript(report.script);
                setIsPublish(report.publish);
                setIsAddAll(report.addAll);
                setConnectedLookupId(report.connectedLookupId);
                setConnectedLookupColumn(report.connectedLookupColumn);
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const saveLookup = () => {
        setLoading(true)
        var url = BASE_URL + 'Lookup/Insert';
        var datamodel = { "Name": lookupName, "Description": lookupDescription, "DataSource": lookupDatasource, "ConnectionString": lookupConnectionString, "Script": lookupScript, "Publish": isPublish, "AddAll": isAddAll, "ModifiedBy": '1', 'ConnectedLookupId': connectedLookupId, 'ConnectedLookupColumn': connectedLookupColumn }

        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message === 'success') {
                    setLookups(data.data);
                    resetForm();
                    CustomAlert('Lookup field has been created successfuly', 'success')
                }
                else {
                    CustomAlert(data.message, 'error')
                }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const updateLookup = (Id) => {
        setLoading(true)
        var url = BASE_URL + 'Lookup/Update';
        var datamodel = { "Id": Id, "Name": lookupName, "Description": lookupDescription, "DataSource": lookupDatasource, "ConnectionString": lookupConnectionString, "Script": lookupScript, "Publish": isPublish, "AddAll": isAddAll, "ModifiedBy": '1', 'ConnectedLookupId': connectedLookupId, 'ConnectedLookupColumn': connectedLookupColumn }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                setLookups(data.data);
                if (data.message !== 'success') {
                    CustomAlert(data.message, 'error')
                }
                else {
                    setIsEdit(false);
                    resetForm();
                    setSelectedLookupId('');
                    CustomAlert('Lookup field has been updated successfuly', 'success')
                }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const deleteLookup = (Id, Name) => {
        if (window.confirm("Are you sure you want to delete the lookup?") === true) {
            setLoading(true)
            var url = BASE_URL + 'Lookup/Delete?Id=' + Id + '&Name=' + Name;
            GetJSON(url, props.instance)
                .then((data) => {
                    setLoading(false)
                    if (data.status === 'failed') {
                        CustomAlert(data.message, 'error')
                    }
                    else {
                        setLookups(data.records);
                        resetForm();
                    }
                })
                .catch((e) => {
                    setLoading(false)
                    CustomAlert(e.message, 'error');
                });
        }
    }
    const previewDataByConnection = (UserId) => {
        setPreviewLoading(true)
        var url = BASE_URL + 'Lookup/PreviewByConnection?UserId=' + UserId;
        var datamodel = { "DataSource": lookupDatasource, "ConnectionString": lookupConnectionString, "Script": lookupScript }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setPreviewLoading(false)
                if (IsNullOrEmptyOrUndefined(data.records) === false && data.records.length > 0) {
                    setQueryRecords(data.records)
                    setOpenPreviewDialog(true)
                    setQueryTimeToExecute(data.duration)
                }
                setQueryMessage(data.message)
                setQueryStatusCode(data.status)
            })
            .catch((e) => {
                setPreviewLoading(false)
                CustomAlert(e.message, 'error');
                setQueryStatusCode('error')
            });
    }
    const previewDataById = (Id, UserId) => {
        setPreviewLoading(true)
        var url = BASE_URL + 'Lookup/PreviewById?Id=' + Id + '&UserId=' + UserId;
        GetJSON(url, props.instance)
            .then((data) => {
                setPreviewLoading(false)
                if (IsNullOrEmptyOrUndefined(data.records) === false && data.records.length > 0) {
                    setQueryRecords(data.records)
                    setOpenPreviewDialog(true)
                    setQueryTimeToExecute(data.duration)
                }
                if (data.message !== 'success') {
                    CustomAlert(data.message, data.status);
                }
                setQueryMessage(data.message)
                setQueryStatusCode(data.status)
            })
            .catch((e) => {
                setPreviewLoading(false)
                CustomAlert(e.message, 'error');
                setQueryStatusCode('error')
            });
    }
    /** API Calls End */

    const validateReportForm = () => {
        var isValid = true;
        if (IsNullOrEmptyOrUndefined(lookupName) || lookupName.length > 50 || lookupName.length < 5 || ValidateInput(lookupName) === false) {
            CustomAlert('Lookup Name should be more than 5 and less than 50 char with no special char', 'error')
            return false;
        }
        if (lookupName.trim().split(' ').length > 1) {
            CustomAlert('Lookup name should not have space', 'error')
            return false;
        }
        if (IsNullOrEmptyOrUndefined(lookupDescription) === false) {
            if (ValidateInput(lookupDescription) === false || lookupDescription.length > 250) {
                CustomAlert('Lookup Description should not contain special char', 'error')
                return false;
            }
        }
        if (IsNullOrEmptyOrUndefined(lookupDatasource)) {
            CustomAlert('Lookup Datasource should be empty', 'error')
            return false;
        }
        if (IsNullOrEmptyOrUndefined(lookupConnectionString) || IsNullOrEmptyOrUndefined(lookupScript)) {
            CustomAlert('Connection, Script field should not be empty', 'error')
            return false;
        }
        return true;
    }
    const handleCreate = () => {
        var isvalidate = validateReportForm()
        if (isvalidate === undefined || isvalidate === false) return;


        if (isEdit)
            updateLookup(selectedLookupId);
        else
            saveLookup();
    }
    const handleBuildNewReport = () => {
        handleReset();
        setEventName('NewLookup')
        setTimeout(() => { window.scrollTo(0, document.body.scrollHeight); }, 100);
    }
    const handleAction = (LookupId, EventName) => {
        setEventName(EventName);
        document.body.scrollTop = 150;
        document.documentElement.scrollTop = 150;

        if (EventName === 'Edit') {
            getById(LookupId)

            setIsEdit(true);
            setSelectedLookupId(LookupId);

        }
    }
    const reportColumns = [
        { field: 'id', headerName: 'Id' },
        { field: 'reportType', headerName: 'Report Type' },
        {
            field: 'status', headerName: '', width: 35, minWidth: 35, editable: false, sortable: false, align: 'center',
            renderCell: (cell) => {
                return (
                    <>
                        <Avatar sx={{ marginTop: '12px', width: 24, height: 24, bgcolor: (cell.row.publish) ? '#dcefdc' : '#fce8e8' }}>
                            {(cell.row.publish) ? <Done sx={{ fontSize: 18, color: '#2e7d32' }} /> : <Close sx={{ fontSize: 18, color: '#d32f2f' }} />}
                        </Avatar>
                    </>
                );
            },
        },
        {
            field: 'name', headerName: '', editable: false, flex: 1,
            renderCell: (cell) => {
                return (
                    <>
                        <CardHeader title={<Typography style={{ fontSize: 14 }}>{cell.row.name}</Typography>} subheader={IsNullOrEmptyOrUndefined(cell.row.published) ? 'Not Published' : 'Published: ' + dayjs(cell.row.published).utc('z').local().format(dateformat)} subheaderTypographyProps={{ variant: 'caption' }} style={{ padding: 4 }} />
                    </>
                );
            },
        },
        {
            field: 'edit', headerName: '', width: 60, sortable: false, align: 'center',
            renderCell: (cell) => {
                return (
                    <>
                        <Tooltip title='Delete'><IconButton size='small' aria-label="delete" onClick={() => deleteLookup(cell.row.id, cell.row.name)}><DeleteOutlined sx={{ fontSize: 22 }} color='error' /></IconButton></Tooltip>
                    </>
                );
            },
        },
    ];
    const handlePreview = () => {
        setQueryRecords([]);
        if (IsNullOrEmptyOrUndefined(lookupDatasource) === false && IsNullOrEmptyOrUndefined(lookupConnectionString) === false && IsNullOrEmptyOrUndefined(lookupScript) === false) {
            previewDataByConnection(userinfo.userid);
        }
        else {
            CustomAlert('Please enter all the mandatory fields', 'error')
        }

    }
    const handleReset = () => {
        resetForm()
        setIsEdit(false);
        setSelectedLookupId('');
        setSelectedReportName('')
    }
    const resetForm = () => {
        setLookupName('')
        setLookupDescription('')
        setLookupDatasource('')
        setLookupConnectionString('')
        setLookupScript('')
        setIsPublish(false)
        setIsAddAll(false)
        setQueryStatusCode('info')
        setQueryMessage('')

    }
    const handleLookupName = (e) => {
        if (e.target.value.length > 100) {
            CustomAlert("Character limit is exceeded", 'info');
        }
        else {
            setLookupName(e.target.value);
        }
    }
    const handleLookupDescription = (e) => {
        if (e.target.value.length > 250) {
            CustomAlert("Character limit is exceeded", 'info');
        }
        else {
            setLookupDescription(e.target.value);
        }
    }
    const handleLookupDatasource = (e) => {
        if (e.target.value.length > 150) {
            CustomAlert("Character limit is exceeded", 'info');
        }
        else {
            setLookupDatasource(e.target.value);
        }
    }
    const handleLookupConnectionString = (event) => {
        setLookupConnectionString(event.target.value);
    };
    const handleLookupScript = (e) => {
        if (e.target.value.length > 2000) {
            CustomAlert("Character limit is exceeded", 'info');
        }
        else {
            setLookupScript(e.target.value);
        }
    }
    const handleCloseDialog = () => {
        setOpenPreviewDialog(false)
    }
    const handleConnectedLookupName = (e) => {
        setConnectedLookupId(e.target.value)
        if (e.target.value === 0) {
            setConnectedLookupColumn('')
        }
    }
    const handleConnectedLookupColumn = (e) => {
        setConnectedLookupColumn(e.target.value)
    }
    const handleReportRowClicked = (rows, event) => {
        handleAction(rows.id, 'Edit')
    }
    return (
        <>
            <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: 2 }} columns={{ xs: 3.5, sm: 8, md: 12 }}>
                <Grid item xs={3.5}>
                    <Card variant="outlined" style={{ height: window.innerHeight - 80 }}>
                        <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title="Lookup Explorer" titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}> <List /></Avatar>} action={<Button variant="outlined" disableElevation sx={{ textTransform: 'none', height: 30 }} onClick={handleBuildNewReport}>New Lookup</Button>} />
                        <Collapse in={true}>
                            <Divider />
                            <Box sx={{ height: window.innerHeight - 125, width: '100%' }}>
                                <DataGrid rowHeight={50} columnHeaderHeight={0} rows={lookups} columns={reportColumns} disableColumnMenu loading={landing}
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 10, }, },
                                        columns: { columnVisibilityModel: { id: false, reportType: false }, },
                                    }}
                                    pageSizeOptions={[10]} disableRowSelectionOnClick
                                    sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-row:hover': { backgroundColor: '#ccdcff', cursor: 'pointer' }, '& .MuiDataGrid-iconSeparator, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                                    onRowClick={(param, event) => {
                                        if (!event.ignore) handleReportRowClicked(param.row, event);
                                    }}
                                />
                            </Box>
                        </Collapse>
                    </Card>
                </Grid>
                <Grid item xs={8.5}>

                    {(eventName === 'NewLookup' || eventName === 'Edit') &&
                        <Card variant="outlined" style={{ height: window.innerHeight - 80 }}>
                            <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title={isEdit ? 'Update the Lookup List' : 'Create a New Lookup List'} titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}> <Addchart /></Avatar>} />
                            <Collapse in={true}>
                                <Divider />
                                <Grid container style={{ textAlign: 'left', justifyContent: 'left', backgroundColor: '#eef2f6', height: 40 }}>
                                    <Grid item>
                                        <Tooltip title='Preview'><IconButton size='small' disabled={previewLoading} onClick={handlePreview}>{previewLoading ? <CircularProgress size={24} /> : <PlayArrow color='primary' />}</IconButton></Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title='Save'><IconButton size='small' disabled={loading} onClick={handleCreate}>{loading ? <CircularProgress size={24} /> : <Save color='primary' />}</IconButton></Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title='Cancel'> <IconButton size='small' onClick={handleReset}><HighlightOff sx={{ color: 'red' }} /></IconButton></Tooltip>
                                    </Grid>
                                    <Grid item style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        <Divider orientation='vertical' style={{ height: 35 }} />
                                    </Grid>
                                    <Grid item>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={isPublish} onChange={(o) => setIsPublish(o.target.checked)} />} label={<Typography style={{ fontSize: 13 }}>Publish</Typography>} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={isAddAll} />} onChange={(o) => setIsAddAll(o.target.checked)} label={<Typography style={{ fontSize: 13 }}>Add All</Typography>} />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <CardContent style={{ padding: 8 }}>
                                    <Grid container spacing={1} style={{ paddingTop: 5, paddingBottom: 10 }}>
                                        <Grid item xs={2}>
                                            <FormControl sx={{ width: '100%' }} size="small" required>
                                                <InputLabel style={{ fontSize: 13 }}>Data Source</InputLabel>
                                                <Select label="Data Source" style={{ fontSize: 13 }} value={lookupDatasource} onChange={(o) => handleLookupDatasource(o)}>
                                                    {
                                                        Sources.map((name) => (<MenuItem key={name.id} value={name.id} style={{ fontSize: 13 }}>{name.name}</MenuItem>))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField required inputProps={{ maxLength: 25, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} style={{ width: '100%' }} size='small' label="Name" variant="outlined" value={lookupName} onChange={(e) => handleLookupName(e)} />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField inputProps={{ maxLength: 250, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} style={{ width: '100%' }} size='small' label="Description" variant="outlined" value={lookupDescription} onChange={(e) => handleLookupDescription(e)} />
                                        </Grid>

                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            {
                                                (lookupDatasource === 'azuresqldatabase' || lookupDatasource === 'mssqlserver' || lookupDatasource === 'mysql') &&
                                                <>
                                                    <Grid container spacing={2} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 5 }}>
                                                        <Grid item xs={12}>
                                                            <TextField spellCheck={false} required inputProps={{ maxLength: 500, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' id="outlined-connection" multiline rows={3} label="Connection string" variant="outlined" style={{ width: '100%' }} value={lookupConnectionString} onChange={(o) => handleLookupConnectionString(o)} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 15 }}>
                                                        <Grid item xs={12}>
                                                            <TextField spellCheck={false} required inputProps={{ style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' id="outlined-script" multiline rows={8} label="Script" variant="outlined" style={{ width: '100%' }} value={lookupScript} onChange={(o) => handleLookupScript(o)} />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                (lookupDatasource === 'REST API') &&
                                                <>
                                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                                                        <Grid item xs={1}>
                                                            <FormControl sx={{ width: '100%' }} size="small" required>
                                                                <InputLabel style={{ fontSize: 13 }} id="demo-simple-select-Method">Method</InputLabel>
                                                                <Select id="demo-simple-Method" label="Method" style={{ fontSize: 13 }} value={restMethod} onChange={(o) => setRestMethod(o.target.value)}>
                                                                    {
                                                                        dRestMethod.map((name) => (<MenuItem key={name} value={name} style={{ fontSize: 13 }}>{name}</MenuItem>))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <TextField required inputProps={{ style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="URL" variant="outlined" style={{ width: '100%' }} value={restUrl} onChange={(o) => setRestUrl(o.target.value)} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 15 }}>
                                                        <Grid item xs={11}>
                                                            <TextField inputProps={{ style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Body" variant="outlined" style={{ width: '100%' }} value={restUrl} onChange={(o) => setRestUrl(o.target.value)} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 15 }}>
                                                        <Grid item xs={11}>
                                                            <TextField inputProps={{ style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Authorization" variant="outlined" style={{ width: '100%' }} value={restUrl} onChange={(o) => setRestUrl(o.target.value)} />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                IsNullOrEmptyOrUndefined(lookupDatasource) === false &&
                                                <Grid container spacing={2} style={{ marginTop: 5 }} >
                                                    <Grid item>
                                                        <FormControl sx={{ width: 180 }} size="small">
                                                            <InputLabel style={{ fontSize: 13 }}>Connected Lookup</InputLabel>
                                                            <Select style={{ fontSize: 13 }} label='Connected Lookup' value={connectedLookupId} onChange={handleConnectedLookupName}>
                                                                <MenuItem value={0} style={{ fontSize: 13 }}>{'-Select Lookup-'}</MenuItem>
                                                                {
                                                                    lookups.map((item, index) => (<MenuItem key={item.name + index} value={item.id} style={{ fontSize: 13 }}>{item.name}</MenuItem>))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControl sx={{ width: 180 }} size="small">
                                                            <InputLabel style={{ fontSize: 13 }}>Id column</InputLabel>
                                                            <Select style={{ fontSize: 13 }} label='Id column' value={connectedLookupColumn} onChange={handleConnectedLookupColumn}>
                                                                <MenuItem value={''} style={{ fontSize: 13 }}>{'-Select Lookup Field-'}</MenuItem>
                                                                {
                                                                    lookups.filter((o) => o.id === connectedLookupId).length > 0 && lookups.filter((o) => o.id === connectedLookupId)[0].columns.split(',').map((item, index) => (<MenuItem key={item + index} value={item} style={{ fontSize: 13 }}>{item}</MenuItem>))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: 5 }}>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontSize: 13, paddingLeft: 10 }}>{(queryMessage === 'Success') ? 'Query execution time (hours:minutes:seconds): ' + queryTimeToExecute : queryMessage}</Typography>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Card >
                    }
                </Grid>
            </Grid >
            <Dialog open={openPreviewDialog} fullWidth={true} onClose={handleCloseDialog} style={{ padding: 0, overflowY: 'scroll', overflowX: 'scroll' }} maxWidth={'md'}>
                <Card>
                    <CardHeader style={{ padding: 6, paddingLeft: 10, paddingRight: 10, backgroundColor: '#e6e6e6' }} title={'Data Preview'} titleTypographyProps={{ variant: 'body1' }} action={<IconButton aria-label="Close" onClick={handleCloseDialog}><Close color='error' /></IconButton>} />
                    <Divider />
                    <CardContent style={{ overflowY: 'scroll', overflowX: 'scroll', maxHeight: 400, padding: 0 }}>
                        {
                            (queryRecords.length > 0) && (
                                <>
                                    <Table size="small" style={{ overflowY: 'scroll', overflowX: 'scroll', textAlign: 'left', width: '100%', borderCollapse: 'separate' }}>
                                        <TableBody>
                                            <TableRow>
                                                {
                                                    Object.keys(queryRecords[0]).map((col, ind) => (<TableCell key={'colheader' + ind} style={{ fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>{TitleCase(col)}</TableCell>))
                                                }
                                            </TableRow>
                                            {
                                                queryRecords.map((row, ind) => (
                                                    <TableRow key={'row' + ind + 2} >
                                                        {
                                                            Object.keys(queryRecords[0]).map((col, ind) => (<TableCell key={'col' + ind + 3} style={{ fontSize: 12, textAlign: 'left', }}>{row[col]}</TableCell>))
                                                        }
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </>
                            )
                        }
                    </CardContent>
                    <Alert severity='warning'>Only top 10 records will be visible here at max.</Alert>
                </Card>
            </Dialog>
        </>
    );

}
