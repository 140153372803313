import React, { useEffect, useState, useRef } from 'react';
import { InsightsOutlined, Addchart, Search, Close, DeleteOutlined, Done, EditOutlined, HistoryToggleOff, List, Save, HighlightOff, PlayArrow, MoreHoriz, Share } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CircularProgress, Paper, InputBase, Menu, ListItemIcon, Alert, Avatar, Box, Button, Card, CardContent, CardHeader, Collapse, Dialog, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, Switch, Tab, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography, CardActions } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { CustomAlert } from '../Utilities/CustomAlert';
import { CountString, IsNullOrEmptyOrUndefined, TitleCase, ValidateInput } from '../Utilities/String';
import { ReportAccess } from '../UserManagement/ReportAccess';
import { ReportHistory } from './ReportHistory';
import { BASE_URL } from '../Utilities/APIURLs';
import { AdminInsight } from './AdminInsight';
import { useSelector } from 'react-redux'
import { GetJSON, PostJSON } from '../Utilities/APICall';


export function ReportMaster(props) {
    const userinfo = useSelector((state) => state.userinfo);
    const employeeId = userinfo.employeeid;
    const loginId = userinfo.loginid;

    const [userId, setUserId] = useState(userinfo.userid);
    const dateformat = 'DD MMM YY HH:mm:ss';
    const [lookupNameList, setLookupNameList] = useState([]);
    const [lookupColumnList, setLookupColumnList] = useState([]);
    const [reports, setReports] = useState([]);
    const [reportSearch, setReportSearch] = useState([]);
    const [reportName, setReportName] = useState('');
    const [reportDescription, setReportDescription] = useState('');
    const [reportDatasource, setReportDatasource] = useState('');
    const [reportConnectionString, setReportConnectionString] = useState('');
    const [reportScript, setReportScript] = useState('');
    const [isPublish, setIsPublish] = useState(false);
    const [reportFormat, setReportFormat] = useState('Table');
    const [downloadFormat, setDownloadFormat] = useState('Csv');


    const [expandNewReport, setExpandNewReport] = useState(true);
    const [transformIcon, setTransformIcon] = useState('rotate(180deg)');
    const [expandRM, setExpandRM] = useState(true);
    const [transformRMIcon, setTransformRMIcon] = useState('rotate(180deg)');
    const transition = 'all 1s';

    const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingParameter, setLoadingParameter] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [loadingPreview, setLoadingPreview] = React.useState(false);

    const [isReportEdit, setIsReportEdit] = React.useState(false);
    const [selectedReportId, setSelectedReportId] = React.useState(0);
    const [selectedReportName, setSelectedReportName] = React.useState('');
    const [selectedRefresh, setSelectedRefresh] = React.useState('');
    const [queryMessage, setQueryMessage] = React.useState('');
    const [dataset, setDataset] = useState({});

    const [queryStatusCode, setQueryStatusCode] = React.useState('info');
    const [queryTimeToExecute, setQueryTimeToExecute] = React.useState('');
    const [tabValue, setTabValue] = React.useState('1');
    const [tabPreValue, setTabPreValue] = React.useState(1);

    const [parameters, setParameters] = React.useState([]);
    const [restMethod, setRestMethod] = React.useState('GET');
    const [restUrl, setRestUrl] = React.useState('');
    const [reportInfo, setReportInfo] = React.useState({});
    const [accessInfo, setAccessInfo] = React.useState({});

    const [eventName, setEventName] = React.useState('Insight');
    const [paramInfo, setParamInfo] = React.useState({ 'dataType': '', 'name': '' });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openDMenu = Boolean(anchorEl);
    const [searchtext, setSearchtext] = useState('');

    const downloadExt = [{ 'display': '-Select-', 'value': '' }, { 'display': 'Csv', 'value': 'Csv' }, { 'display': 'Xlsx', 'value': 'Xlsx' }, { 'display': 'Csv and Xlsx', 'value': 'Csv and Xlsx' }];
    const dSources = [{ 'name': 'Azure SQL Database', 'id': 'azuresqldatabase' }, { 'name': 'MS SQL Sqlserver', 'id': 'mssqlserver' }, { 'name': 'MySQL', 'id': 'mysql' }];
    const dType = ['Number', 'String', 'Date', 'DateTime', 'Time', 'Yes/No', 'Choice', 'Lookup'];
    const dRestMethod = ['GET', 'POST', 'PUT'];
    const dReturnType = ['Table', 'Html'];
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (userId > 0 && dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            getDefinition()
            getLookupList()
        }
    }, [userId]);

    /** API Calls */
    const getDefinition = () => {
        setLoading(true)
        var url = BASE_URL + 'ReportMaster/Get'
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                setReports(data);
                setReportSearch(data);
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const getById = (Id) => {
        setLoading(true)
        var url = BASE_URL + 'ReportMaster/GetById?Id=' + Id
        GetJSON(url, props.instance)
            .then(report => {
                setLoading(false)
                setReportName(report.name);
                setReportDescription(report.description);
                setReportDatasource(report.dataSource);
                setReportConnectionString(report.dataConnection);
                setReportScript(report.script);
                setIsPublish(report.publish);
                if (IsNullOrEmptyOrUndefined(report.reportFormat) === false) setReportFormat(report.reportFormat);
                setDownloadFormat(report.downloadFormat);
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const getLookupList = () => {
        setLoading(true)
        var url = BASE_URL + 'Lookup/Get'
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                setLookupNameList(data);
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const getReportParameter = (ReportId) => {
        setParameters([])
        setLoadingParameter(true)
        var url = BASE_URL + 'ReportMaster/GetParameterByReportId?ReportId=' + ReportId;
        GetJSON(url, props.instance)
            .then(data => {
                setLoadingParameter(false)
                setParameters(data);
            })
            .catch((e) => {
                setLoadingParameter(false)
                CustomAlert(e.message, 'error');
            });
    }
    const saveReportDefinition = () => {
        setLoadingSave(true)
        var url = BASE_URL + 'ReportMaster/InsertDefinition'
        var datamodel = { "Name": reportName, "Description": reportDescription, "DataSource": reportDatasource, "DataConnection": reportConnectionString, "Script": reportScript, "Publish": isPublish, "ReportFormat": reportFormat, "DownloadFormat": downloadFormat, "ModifiedBy": userinfo.userid, "Parameter": parameters }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoadingSave(false)
                if (data.message === 'success') {
                    setReports(data.data);
                    setReportSearch(data.data);
                    resetReportForm();
                    CustomAlert('Report has created successfuly', 'success')
                }
                else {
                    CustomAlert(data.message, 'error')
                }
            })
            .catch((e) => {
                setLoadingSave(false)
                CustomAlert(e, 'error');
            });

    }
    const updateReportDefinition = (Id) => {
        setLoadingSave(true)
        var url = BASE_URL + 'ReportMaster/UpdateDefinition'
        var datamodel = { "Id": Id, "Name": reportName, "Description": reportDescription, "DataSource": reportDatasource, "DataConnection": reportConnectionString, "Script": reportScript, "Publish": isPublish, "ReportFormat": reportFormat, "DownloadFormat": downloadFormat, "ModifiedBy": userinfo.userid, "Parameter": parameters }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoadingSave(false)
                setReports(data.data);
                setReportSearch(data.data);

                if (data.message !== 'success') {
                    CustomAlert(data.message, 'error')
                }
                else {
                    setIsReportEdit(false);
                    resetReportForm();
                    setSelectedReportId('');
                    CustomAlert('Report has updated successfuly', 'success')
                }
            })
            .catch((e) => {
                setLoadingSave(false)
                CustomAlert(e.message, 'error');
            });
    }
    const deleteReportDefinition = (Id) => {
        setLoading(true)
        var url = BASE_URL + 'ReportMaster/DeleteDefinition?ReportId=' + Id;
        GetJSON(url, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.status === 'Failed') {
                    CustomAlert(data.message, 'error')
                }
                else {
                    setReports(data.data);
                    setReportSearch(data.data);
                    setEventName('Insight')
                }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const previewDataSource = (LoginId) => {
        setLoadingPreview(true)
        var url = BASE_URL + 'ReportMaster/PreviewReport?LoginId=' + LoginId
        var datamodel = { "DataSource": reportDatasource, "DataConnection": reportConnectionString, "Script": reportScript }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoadingPreview(false)
                if (IsNullOrEmptyOrUndefined(data.records) === false && data.records.length > 0) {
                    setDataset(JSON.parse(data.records))
                    setOpenPreviewDialog(true)
                    setQueryTimeToExecute(data.duration)
                }
                setQueryMessage(data.message)
                setQueryStatusCode(data.status)

            })
            .catch((e) => {
                setLoadingPreview(false)
                CustomAlert(e.message, 'error');
                setQueryStatusCode('error')
            });
    }
    /** API Calls End */

    const validateReportForm = () => {
        if (IsNullOrEmptyOrUndefined(reportName) || reportName.length > 50 || reportName.length < 5 || ValidateInput(reportName) === false) {
            CustomAlert('Report Name should be more than 5 and less than 50 char with no special char', 'error')
            return false;
        }
        if (IsNullOrEmptyOrUndefined(reportDescription) === false) {
            if (ValidateInput(reportDescription) === false) {
                CustomAlert('Report Description should not contain special char', 'error')
                return false;
            }
        }
        if (IsNullOrEmptyOrUndefined(reportDatasource)) {
            CustomAlert('Report Datasource should be empty', 'error')
            return false;
        }
        if (IsNullOrEmptyOrUndefined(reportDatasource) && IsNullOrEmptyOrUndefined(reportConnectionString) && IsNullOrEmptyOrUndefined(reportScript)) {
            CustomAlert('Report Datasource,Connection,Script should be empty', 'error')
            return false;
        }
        if (IsNullOrEmptyOrUndefined(reportFormat)) {
            CustomAlert('Report format should be an empty value', 'error')
            return false;
        }
        var pCount = CountString(reportScript, '@');
        if (pCount > 0) {
            if (parameters.length <= 0) {
                CustomAlert('Please configure parameter correctly', 'error')
                return false;
            }
            if (parameters.length > 0) {
                for (const element of parameters) {
                    if (element.dataType === '') {
                        CustomAlert('Please configure parameter correctly', 'error')
                        return false;
                    }
                }
            }
        }
        return true;
    }
    const handleCreate = () => {
        var isvalidate = validateReportForm()
        if (isvalidate === false) return;

        if (isReportEdit)
            updateReportDefinition(selectedReportId);
        else
            saveReportDefinition();
    }
    const handleBuildNewReport = () => {
        handleReset();
        setTransformIcon('rotate(180deg)')
        setExpandNewReport(true)
        setEventName('NewReport')
        setTabValue('1')
        setTimeout(() => { window.scrollTo(0, document.body.scrollHeight); }, 100);
    }
    const handleActionMenu = (Event, ReportId,) => {
        Event.ignore = true;
        setAnchorEl(Event.currentTarget);
        var rpt = reports.filter((o) => o.id === ReportId)[0];
        setSelectedReportId(ReportId);
        setSelectedReportName(rpt.name)
    }
    const handleAction = (EventName) => {
        setEventName(EventName);
        setAnchorEl(null);

        var rpt = reports.filter((o) => o.id === selectedReportId)[0]
        if (EventName === 'Edit') {
            getById(rpt.id)
            getReportParameter(rpt.id)
            setTabValue('1')
            handleAttributes('', '')
            setExpandNewReport(true)
            setIsReportEdit(true);
            setTransformIcon('rotate(180deg)')
        }
        else if (EventName === 'Delete') {
            if (window.confirm("Are you sure you want to delete the selected report?") === true) {
                deleteReportDefinition(rpt.id);
            }
        }
        else if (EventName === 'Share') {
            var rptObject = { ReportId: rpt.id, ReportName: rpt.name };
            setAccessInfo(rptObject);
        }
        else if (EventName === 'History') {
            var rptObject = { ReportId: rpt.id, ReportName: rpt.name, ReportFormat: rpt.reportFormat, UserId: '' };
            setReportInfo(rptObject)
            setSelectedRefresh(new Date());
        }
    }
    const reportColumns = [
        { field: 'id', headerName: 'Id', width: 90 },

        {
            field: 'description', headerName: '', width: 35, minWidth: 35, editable: false, sortable: false, align: 'center',
            renderCell: (cell) => {
                return (
                    <>
                        <Avatar sx={{ marginTop: '12px', width: 24, height: 24, bgcolor: '#e3f2fd' }}>{(cell.row.publish) ? <Done sx={{ fontSize: 18, color: '#673ab7' }} /> : <Close sx={{ fontSize: 18, color: '#673ab7' }} />}</Avatar>
                    </>
                );
            },
        },
        {
            field: 'name', headerName: '', editable: false, flex: 1,
            renderCell: (cell) => {
                return (
                    <>
                        <CardHeader title={<Typography style={{ fontSize: 14 }}>{cell.row.name}</Typography>} subheader={IsNullOrEmptyOrUndefined(cell.row.published) ? 'Not Published' : 'Published: ' + dayjs(cell.row.published).utc('z').local().format(dateformat)} subheaderTypographyProps={{ variant: 'caption' }} style={{ padding: 5 }} />
                    </>
                );
            },
        },
        {
            field: 'edit', headerName: '', sortable: false, align: 'center', width: 60, minWidth: 60,
            renderCell: (cell) => {
                return (
                    <>
                        <IconButton size='small' aria-label="Action" onClick={(e) => handleActionMenu(e, cell.row.id)}><MoreHoriz sx={{ fontSize: 22 }} color='primary' /></IconButton>
                    </>
                );
            },
        },
    ];
    const parameterColumns = [
        { field: 'id', headerName: 'Id', width: 40 },
        { field: 'parameterId', headerName: 'PId', width: 50 },
        { field: 'name', headerName: 'Name', sortable: false, width: 130 },
        {
            field: 'dataType', headerName: 'DataType', sortable: false, flex: 1, editable: true,
            renderCell: (cell) => {
                return (
                    <>
                        <FormControl sx={{ width: '100%', padding: 0, border: 0, marginTop: '2px' }} size="small" required>
                            <Select labelId="demo-simple-select-datasource" id="demo-simple-source" style={{ border: 0, fontSize: 13, padding: 0 }} value={cell.row.dataType} onChange={(event) => handleDataTypeChange(event, cell.row.name)}>
                                {
                                    dType.map((name) => (<MenuItem key={name} value={name} style={{ fontSize: 13 }}>{name}</MenuItem>))
                                }
                            </Select>
                        </FormControl >
                    </>
                );
            },
        },
        {
            field: 'properties', headerName: '', align: 'center', sortable: false, editable: false, width: 100,
            renderCell: (cell) => {
                return (
                    <>
                        <Button variant="outlined" size='small' aria-label="property" style={{ textTransform: 'none', width: 80, height: 30 }} onClick={() => handleAttributes(cell.row.dataType, cell.row.name)}>Properties</Button>
                    </>
                );
            },
        },
    ];
    const handleExpandReport = () => {
        if (expandNewReport === false) {
            setExpandNewReport(true)
            setTransformIcon('rotate(180deg)')
        }
        else {
            setExpandNewReport(false)
            setTransformIcon('rotate(0deg)')
        }
    }
    const handleRM = () => {
        if (expandRM === false) {
            setExpandRM(true)
            setTransformRMIcon('rotate(180deg)')
        }
        else {
            setExpandRM(false)
            setTransformRMIcon('rotate(0deg)')
        }
    }
    const handlePreview = () => {
        setDataset([]);
        if (IsNullOrEmptyOrUndefined(reportDatasource) === false && IsNullOrEmptyOrUndefined(reportConnectionString) === false && IsNullOrEmptyOrUndefined(reportScript) === false) {
            previewDataSource(loginId);
        }
        else {
            CustomAlert('Please enter the mandatory field.', 'error')
        }

    }
    const handleReset = () => {
        resetReportForm()
        setIsReportEdit(false);
        setSelectedReportId('');
        setSelectedReportName('')
        setTabValue('1')
    }
    const resetReportForm = () => {
        setReportName('')
        setReportDescription('')
        setReportDatasource('')
        setReportConnectionString('')
        setReportScript('')
        setIsPublish(false)
        setQueryStatusCode('info')
        handleAttributes('', '')
        setParameters([]);
        setQueryMessage('')
        setDownloadFormat('Csv')
    }
    const handleReportName = (e) => {
        if (e.target.value.length > 100) {
            CustomAlert("Character limit is exceeded", 'info');
        }
        else {
            setReportName(e.target.value);
        }
    }
    const handleReportDescription = (e) => {
        if (e.target.value.length > 250) {
            CustomAlert("Character limit is exceeded", 'info');
        }
        else {
            setReportDescription(e.target.value);
        }
    }
    const handleReportDatasource = (e) => {
        if (e.target.value.length > 150) {
            CustomAlert("Character limit is exceeded", 'info');
        }
        else {
            setReportDatasource(e.target.value);
        }
    }
    const handleReportConnectionString = (event) => {
        setReportConnectionString(event.target.value);
    };
    const handleScript = (e) => {
        if (e.target.value.length > 2000) {
            CustomAlert("Character limit is exceeded", 'info');
        }
        else {
            setReportScript(e.target.value);
        }
    }
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === '2') {
            handleParameter();
        }
    };
    const handleSearchChange = (e) => {
        setSearchtext(e.target.value);

        if (IsNullOrEmptyOrUndefined(e.target.value)) {
            setReportSearch(reports);
        }
        else {
            if (ValidateInput(e.target.value)) {
                handleSearch(e.target.value);
            }
        }
    }
    const handleSearch = (searchValue) => {
        if (IsNullOrEmptyOrUndefined(searchValue))
            setReportSearch(reports);
        var matches = reports.filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase()));
        setReportSearch(matches);
    }
    const handleSearchClear = () => {
        setSearchtext('');
        setReportSearch(reports);
    }
    const handleParameter = () => {
        var _parameters = []
        let counter = 1
        let word = '';
        let foundAt = false;

        for (const char of reportScript) {
            if (char === '@') {
                foundAt = true;
            } else if (foundAt && (char === ',' || char === ' ')) {
                _parameters.push({ 'parameterId': counter, 'name': '@' + word, 'dataType': '', dataValue: '', defaultValue: null, format: '', calculated: '', items: '', lookupListId: 0, lookupLabel: '', lookupValue: '', multiSelect: false, dateRange: '', minNumber: '', maxNumber: '', maxLength: '' })
                counter = counter + 1
                word = ''
                foundAt = false
            } else if (foundAt) {
                word += char; // Append characters to the word
            }
        }
        //In case of end of line
        if (foundAt && word.length > 0) {
            _parameters.push({ 'parameterId': counter, 'name': '@' + word, 'dataType': '', dataValue: '', defaultValue: null, format: '', calculated: '', items: '', lookupListId: 0, lookupLabel: '', lookupValue: '', multiSelect: false, dateRange: '', minNumber: '', maxNumber: '', maxLength: '' })
            word = ''
            foundAt = false
        }

        //Reconsilation
        if (_parameters.length > 0) {
            _parameters.forEach(item => {
                let find = parameters.find(o => o.name === item.name);
                if (IsNullOrEmptyOrUndefined(find) === false) {
                    item.dataType = find.dataType;
                    item.calculated = find.calculated;
                    item.defaultValue = find.defaultValue;
                    item.format = find.format;
                    item.items = find.items;
                    item.lookupListId = find.lookupListId;
                    item.lookupLabel = find.lookupLabel;
                    item.lookupValue = find.lookupValue;
                    item.multiSelect = find.multiSelect;
                    item.dateRange = find.dateRange;
                    item.minNumber = find.minNumber;
                    item.maxNumber = find.maxNumber;
                    item.maxLength = find.maxLength;
                }
            });
            setParameters(_parameters)
        }
        else {
            setParameters([])
        }
    }
    const processRowUpdate = (newRow) => {
        var update = parameters.find(o => o.name === newRow.name);
        update.dataType = newRow.dataType;
        setParameters(parameters);
    };
    const handleAttributes = (dataType, name) => {
        if (dataType === 'Lookup') {
            var pFilter = parameters.filter((o) => o.name === name)[0]
            var filterCol = lookupNameList.filter((o) => o.id === pFilter.lookupListId)[0]
            if (IsNullOrEmptyOrUndefined(filterCol) === false) {
                var cols = filterCol.columns.split(',');
                setLookupColumnList(cols);
            }
        }
        setParamInfo({ 'dataType': dataType, 'name': name });
    }
    const handleDataTypeChange = (event, fieldName) => {
        const newState = parameters.map(obj => {
            if (obj.name === fieldName) {
                if (event.target.value === 'Date' || event.target.value === 'DateTime' || event.target.value === 'Time')
                    return { ...obj, dataType: event.target.value, defaultValue: null };
                else
                    return { ...obj, dataType: event.target.value, defaultValue: '' };
            }
            return obj;
        });
        setParameters(newState);
        handleAttributes(event.target.value, fieldName);
    }
    const handleDataTypeValueChange = (event, dataType, name, control) => {
        const newState = parameters.map(obj => {
            if (obj.name === name) {
                if (control === 'default') {
                    if (dataType === 'Time') {
                        return { ...obj, defaultValue: event };
                    }
                    else {
                        return { ...obj, defaultValue: event.target.value };
                    }
                }
                else if (control === 'format')
                    return { ...obj, format: event.target.value.trim() };
                else if (control === 'calculated')
                    return { ...obj, calculated: event.target.value };
                else if (control === 'items')
                    return { ...obj, items: event.target.value };
                else if (control === 'lookupList') {
                    var filterCol = lookupNameList.filter((o) => o.id === event.target.value)[0]
                    var cols = filterCol.columns.split(',');
                    setLookupColumnList(cols);
                    return { ...obj, lookupListId: event.target.value };
                }
                else if (control === 'lookupLabel') {
                    return { ...obj, lookupLabel: event.target.value.trim() };
                }
                else if (control === 'lookupValue') {
                    return { ...obj, lookupValue: event.target.value.trim() };
                }
                else if (control === 'multiSelect') {
                    return { ...obj, multiSelect: event.target.checked };
                }
                else if (control === 'daterange') {
                    return { ...obj, dateRange: event.target.value.trim().substring(0, 25) };
                }
                else if (control === 'minnumber') {
                    // if (IsNullOrEmptyOrUndefined(obj.defaultValue) === false) {
                    //     if (Number(event.target.value) > Number(obj.defaultValue)) {
                    //         CustomAlert('Minimum Number can not be greater than default value', 'error')
                    //         return obj;
                    //     }
                    // }
                    return { ...obj, minNumber: event.target.value.trim().substring(0, 25) };
                }
                else if (control === 'maxnumber') {
                    // if (IsNullOrEmptyOrUndefined(obj.defaultValue) === false) {
                    //     if (Number(event.target.value) > Number(obj.defaultValue)) {
                    //         CustomAlert('Maximum Number can not be less than minimum/default value', 'error')
                    //         return obj;
                    //     }
                    // }
                    return { ...obj, maxNumber: event.target.value.trim().substring(0, 25) };
                }
                else if (control === 'maxlength') {
                    return { ...obj, maxLength: event.target.value.trim().substring(0, 5) };
                }
            }
            else {
                return obj;
            }
        });
        setParameters(newState);
    }
    const renderAttributeControl = () => {
        var pInfo = parameters.filter((o) => o.name === paramInfo.name)[0];
        if (paramInfo.dataType === 'Yes/No') {
            return (
                <>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <Typography sx={{ typography: { fontSize: 13 } }}>Default Value</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <FormControl sx={{ width: 200 }} size="small">
                                <Select style={{ fontSize: 13 }} defaultValue={'No'} value={pInfo.defaultValue} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'default')}>
                                    <MenuItem style={{ fontSize: 13 }} value={'Yes'}>{'Yes'}</MenuItem>
                                    <MenuItem style={{ fontSize: 13 }} value={'No'}>{'No'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            )
        }
        else if (paramInfo.dataType === 'Number') {
            return (
                <>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <Typography sx={{ typography: { fontSize: 13 } }}>Default Value</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <TextField inputProps={{ maxLength: 25, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: 200 }} type="number" placeholder="Enter a number" value={pInfo.defaultValue || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'default')} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                        <Grid item>
                            <Typography sx={{ typography: { fontSize: 13 } }}>Mininum allowed value</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <TextField inputProps={{ maxLength: 25, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: 200 }} type="number" placeholder="Enter a number" value={pInfo.minNumber || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'minnumber')} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                        <Grid item>
                            <Typography sx={{ typography: { fontSize: 13 } }}>Maximum allowed value</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <TextField inputProps={{ maxLength: 25, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: 200 }} type="number" placeholder="Enter a number" value={pInfo.maxNumber || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'maxnumber')} />
                        </Grid>
                    </Grid>
                </>
            )
        }
        else if (paramInfo.dataType === 'String') {
            return (
                <>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Default Value</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }}>
                        <Grid item>
                            <TextField inputProps={{ maxLength: 25, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: 200 }} value={pInfo.defaultValue || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'default')} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Max Length</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <TextField inputProps={{ maxLength: 50, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: 200 }} type="number" value={pInfo.maxLength || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'maxlength')} />
                        </Grid>
                    </Grid>
                </>
            )
        }
        else if (paramInfo.dataType === 'Date') {
            return (
                <>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item xs={12}>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Default Value</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <TextField multiline rows={3} inputProps={{ maxLength: 500, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: '100%' }} value={pInfo.defaultValue || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'default')} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item xs={12}>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Validation</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <TextField multiline rows={3} inputProps={{ maxLength: 1000, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: '100%' }} value={pInfo.calculated || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'calculated')} />

                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker slotProps={{ textField: { InputProps: { size: 'small' } } }} sx={{ width: 250, '& .MuiInputBase-input, & .MuiOutlinedInput-input': { fontSize: 14, paddingLeft: '8px', paddingRight: '6px' }, }} format="DD-MM-YYYY" container="inline" value={dayjs(pInfo.defaultValue)} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'calculated')} />
                            </LocalizationProvider> */}
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item xs={12}>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Format</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <TextField inputProps={{ maxLength: 25, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: '50%' }} placeholder="Enter a format of date" value={pInfo.format || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'format')} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <Typography sx={{ typography: { fontSize: 13 } }}>Maximum range value (ex. 2 = +-CurrentDate)</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <TextField inputProps={{ maxLength: 25, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: 250 }} type="number" placeholder="Enter a days" value={IsNullOrEmptyOrUndefined(pInfo.dateRange) ? '' : pInfo.dateRange} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'daterange')} onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                        </Grid>
                    </Grid>

                </>
            )
        }
        else if (paramInfo.dataType === 'DateTime') {
            return (
                <>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item xs={12}>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Default Value</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <TextField inputProps={{ maxLength: 500, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: '100%' }} value={pInfo.defaultValue || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'default')} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item xs={12}>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Validation</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <TextField inputProps={{ maxLength: 1000, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: '100%' }} value={pInfo.calculated || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'calculated')} />

                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker slotProps={{ textField: { InputProps: { size: 'small' } } }} sx={{ width: 250, '& .MuiInputBase-input, & .MuiOutlinedInput-input': { fontSize: 14, paddingLeft: '8px', paddingRight: '6px' }, }} format="DD-MM-YYYY HH:mm:ss" ampm={false} container="inline" value={dayjs(pInfo.defaultValue)} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'default')} />
                            </LocalizationProvider> */}
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item xs={12}>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Format</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <TextField inputProps={{ maxLength: 25, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: '50%' }} value={pInfo.format || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'format')} />
                        </Grid>
                    </Grid>
                </>
            )
        }
        else if (paramInfo.dataType === 'Time') {
            return (
                <>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Calculated</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <TextField inputProps={{ maxLength: 25, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: 250 }} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'calculated')} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: 10 }}>
                        <Grid item>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Default Value</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker slotProps={{ textField: { InputProps: { size: 'small' } } }} sx={{ width: 250, '& .MuiInputBase-input, & .MuiOutlinedInput-input': { fontSize: 13, paddingLeft: '6px', paddingRight: '6px' }, }} format="HH:mm:ss" ampm={false} container="inline" onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'default')} />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                </>
            )
        }
        else if (paramInfo.dataType === 'Choice') {
            return (
                <>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Enter Item in seperate line</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <TextField inputProps={{ style: { fontSize: 13 } }} size='small' multiline rows={5} variant="outlined" sx={{ width: 200 }} value={pInfo.items} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'items')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: 10 }}>
                        <Grid item>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Default Item</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <TextField inputProps={{ maxLength: 25, style: { fontSize: 13 } }} size='small' variant="outlined" sx={{ width: 200 }} value={pInfo.defaultValue || ''} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'default')} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: 10 }}>
                        <Grid item>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Allow multiple selections</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={pInfo.multiSelect} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'multiSelect')} />} label={<Typography style={{ fontSize: 13 }}>{pInfo.multiSelect ? 'Yes' : 'No'}</Typography>} />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </>
            )
        }
        else if (paramInfo.dataType === 'Lookup') {
            return (
                <>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item><Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Select list as a source</Typography></Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingBottom: 15 }}>
                        <Grid item>
                            <FormControl sx={{ width: 200 }} size="small">
                                <Select style={{ fontSize: 13 }} value={pInfo.lookupListId} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'lookupList')}>
                                    {
                                        lookupNameList.map((item, index) => (<MenuItem key={item.name + index} value={item.id} style={{ fontSize: 13 }}>{item.name}</MenuItem>))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item><Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Select Display column from list</Typography></Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingBottom: 15 }}>
                        <Grid item>
                            <FormControl sx={{ width: 200 }} size="small">
                                <Select style={{ fontSize: 13 }} value={pInfo.lookupLabel} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'lookupLabel')}>
                                    {
                                        lookupColumnList.map((item, index) => (<MenuItem key={item + index} value={item} style={{ fontSize: 13 }}>{item}</MenuItem>))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item><Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Select Id column from list</Typography></Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <FormControl sx={{ width: 200 }} size="small">
                                <Select style={{ fontSize: 13 }} value={pInfo.lookupValue} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'lookupValue')}>
                                    {
                                        lookupColumnList.map((item, index) => (<MenuItem key={item + index} value={item} style={{ fontSize: 13 }}>{item}</MenuItem>))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: 10 }}>
                        <Grid item>
                            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Allow multiple selections</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={pInfo.multiSelect} onChange={(e) => handleDataTypeValueChange(e, paramInfo.dataType, paramInfo.name, 'multiSelect')} />} label={<Typography style={{ fontSize: 13 }}>{pInfo.multiSelect ? 'Yes' : 'No'}</Typography>} />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </>
            )
        }
        else {
            <Typography variant='body2' sx={{ typography: { fontSize: 13 } }}>Click Attributes button</Typography>
        }
    }
    const renderDataset = () => {
        return (
            <>
                <TabContext value={tabPreValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, value) => setTabPreValue(value)} sx={{ minHeight: 30, height: 30 }}>
                            {
                                Object.keys(dataset).map((table, index) => (
                                    <Tab style={{ textTransform: 'none', height: 30, minHeight: 30 }} label={table} key={'dataset' + index} value={index + 1} />
                                ))
                            }
                        </TabList>
                    </Box>
                    {
                        Object.keys(dataset).map((table, index) => (
                            <TabPanel value={index + 1} style={{ padding: 0 }}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow style={{ height: 30 }}>
                                            {
                                                Object.keys(dataset[table][0]).map((col, ind) => (<TableCell key={'colheader' + ind} style={{ fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>{col}</TableCell>))
                                            }
                                        </TableRow>
                                        {
                                            dataset[table].map((row, ind) => (
                                                <TableRow key={'row' + ind + 2} >
                                                    {
                                                        Object.keys(dataset[table][0]).map((col, ind) => (<TableCell key={'col' + ind + 3} style={{ fontSize: 12, textAlign: 'left', }}>{row[col]}</TableCell>))
                                                    }
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TabPanel>
                        ))
                    }
                </TabContext>
            </>
        )
    }
    const handleReportRowClicked = (rows, event) => {
        setEventName('Edit');
        var rpt = reports.filter((o) => o.id === rows.id)[0]

        setSelectedReportId(rows.id);
        setSelectedReportName(rpt.name)

        getById(rpt.id)
        getReportParameter(rpt.id)
        setTabValue('1')
        handleAttributes('', '')
        setExpandNewReport(true)
        setIsReportEdit(true);
        setTransformIcon('rotate(180deg)')

    }
    return (
        <>
            <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }} columns={{ xs: 3.5, sm: 8, md: 12 }}>
                <Grid item xs={3.5}>
                    <Card variant="outlined" style={{ marginTop: 10, height: window.innerHeight - 80 }}>
                        <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title="Report Explorer" titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}> <List /></Avatar>} action={<><Button variant="outlined" disableElevation sx={{ textTransform: 'none', height: 30 }} onClick={handleBuildNewReport}>New report</Button><Tooltip title={'Insight'}><Button variant="outlined" size='small' style={{ width: 40, minWidth: 40, height: 30, marginLeft: 5 }} onClick={() => setEventName('Insight')}><InsightsOutlined color='primary' /></Button></Tooltip></>} />
                        <Collapse in={expandRM}>
                            <div style={{ justifyContent: 'center', display: 'flex', textAlign: 'center', width: '100%', marginTop: 5, marginBottom: 5 }}>
                                <Paper elevation={0} sx={{ display: 'flex', alignItems: 'center', width: '95%', border: '1px solid silver' }}>
                                    <Search fontSize='small' sx={{ paddingLeft: 0.5, paddingRight: 0.5 }} color="disabled" />
                                    <InputBase sx={{ flex: 1 }} autoFocus value={searchtext} onChange={handleSearchChange} placeholder="Search report..." inputProps={{ maxLength: 25, 'aria-label': 'Search report...' }} />
                                    <IconButton size="small" onClick={handleSearchClear}><Close fontSize="inherit" sx={{ paddingLeft: 0.5, paddingRight: 0.5, color: 'gray' }} /></IconButton>
                                </Paper>
                            </div>
                            <Divider />
                            <Box sx={{ height: window.innerHeight - 165, width: '100%' }}>
                                <DataGrid rowHeight={50} columnHeaderHeight={0} rows={reportSearch} columns={reportColumns} disableColumnMenu loading={loading}
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 100, }, },
                                        columns: { columnVisibilityModel: { id: false }, },
                                    }}
                                    pageSizeOptions={[100]} hideFooterSelectedRowCount={true}
                                    sx={{ '& .MuiDataGrid-row:hover': { backgroundColor: '#ccdcff', cursor: 'pointer' }, border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                                    onRowClick={(param, event) => {
                                        if (!event.ignore) handleReportRowClicked(param.row, event);
                                    }}
                                />
                            </Box>
                        </Collapse>
                    </Card>

                </Grid>
                <Grid item xs={8.5} style={{ marginTop: 10 }}>
                    {eventName === 'Insight' && <AdminInsight instance={props.instance} />}

                    {(eventName === 'NewReport' || eventName === 'Edit') &&
                        <Card variant="outlined" style={{ height: window.innerHeight - 80 }}>
                            <CardHeader style={{ paddingTop: 10, paddingBottom: 14, paddingLeft: 8 }} title={<>{isReportEdit ? 'Update the Report' : 'Create a New Report'} {IsNullOrEmptyOrUndefined(selectedReportName) === false ? <span style={{ fontSize: 15, fontWeight: 550, paddingLeft: 4 }}>{': ' + selectedReportName}</span> : ''}</>} titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}> <Addchart /></Avatar>} />
                            <Collapse in={expandNewReport}>
                                <Divider />
                                <TabContext value={tabValue}>
                                    <Grid container spacing={1} style={{ backgroundColor: '#eef2f6' }}>
                                        <Grid item style={{ paddingBottom: 0, paddingTop: 4 }}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 216, borderBottom: 0 }}>
                                                <TabList onChange={handleTabChange} aria-label="lab API tabs example" style={{ height: 40, minHeight: 40 }}>
                                                    <Tab style={{ textTransform: 'none', paddingTop: 0 }} label="Connection" value="1" />
                                                    <Tab style={{ textTransform: 'none', paddingTop: 0 }} label="Parameters" value="2" />
                                                </TabList>
                                            </Box>
                                        </Grid>
                                        <Grid item style={{ paddingTop: 4 }}>
                                            <Box style={{ height: 37, minHeight: 37, paddingLeft: 0 }}>
                                                <Grid container style={{ textAlign: 'left', justifyContent: 'left', backgroundColor: '#eef2f6' }}>
                                                    <Grid item style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                        <Divider orientation='vertical' style={{ height: 35 }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title='Preview'><IconButton size='small' disabled={loadingPreview} onClick={handlePreview}>{loadingPreview ? <CircularProgress size={24} /> : <PlayArrow color='primary' />}</IconButton></Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title='Save'><IconButton size='small' disabled={loadingSave} onClick={handleCreate}>{loadingSave ? <CircularProgress size={24} /> : <Save color='primary' />}</IconButton></Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title='Cancel'><IconButton size='small' onClick={handleReset}><HighlightOff sx={{ color: 'red' }} /></IconButton></Tooltip>
                                                    </Grid>
                                                    <Grid item style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                        <Divider orientation='vertical' style={{ height: 35 }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormGroup>
                                                            <FormControlLabel control={<Switch checked={isPublish} onChange={(e) => setIsPublish(e.target.checked)} />} label={<Typography style={{ fontSize: 13 }}>Publish</Typography>} />
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>


                                    <TabPanel value="1" style={{ paddingTop: 3, paddingLeft: 2, paddingRight: 6 }}>
                                        <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                                            <Grid item xs={12}>
                                                <CardContent style={{ padding: 0, paddingLeft: 8 }}>
                                                    <Grid container spacing={1.1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                                                        <Grid item xs={2.5}>
                                                            <FormControl sx={{ width: '100%' }} size="small" required>
                                                                <InputLabel style={{ fontSize: 13 }} id="demo-simple-select-datasource">Data Source</InputLabel>
                                                                <Select id="demo-simple-source" label="Data Source" style={{ fontSize: 13 }} value={reportDatasource} onChange={(o) => handleReportDatasource(o)}>
                                                                    <MenuItem value={''} style={{ fontSize: 13 }}>{'-Select DataSource--'}</MenuItem>
                                                                    {
                                                                        dSources.map((name) => (<MenuItem key={name.id} value={name.id} style={{ fontSize: 13 }}>{name.name}</MenuItem>))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={1.5}>
                                                            <FormControl sx={{ textAlign: 'left', width: '100%' }} size="small" required>
                                                                <InputLabel style={{ fontSize: 13 }}>Return Format</InputLabel>
                                                                <Select label='-Return Format-' defaultValue={'Table'} style={{ fontSize: 13 }} value={reportFormat} onChange={(o) => setReportFormat(o.target.value)}>
                                                                    {
                                                                        dReturnType.map((name) => (<MenuItem key={name} value={name} style={{ fontSize: 13 }}>{name}</MenuItem>))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={1.5}>
                                                            <FormControl sx={{ textAlign: 'left', width: '100%' }} size="small" required>
                                                                <InputLabel style={{ fontSize: 12 }}>Download Format</InputLabel>
                                                                <Select label='Download Format' style={{ fontSize: 13 }} value={downloadFormat} onChange={(o) => setDownloadFormat(o.target.value)}>
                                                                    {
                                                                        downloadExt.map((name) => (<MenuItem key={name.display} value={name.value} style={{ fontSize: 13 }}>{name.display}</MenuItem>))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField required inputProps={{ maxLength: 50, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Name" variant="outlined" style={{ width: '100%' }} value={reportName} onChange={(e) => handleReportName(e)} />
                                                        </Grid>

                                                        <Grid item xs={4.5}>
                                                            <TextField inputProps={{ maxLength: 250, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Description" variant="outlined" style={{ width: '100%' }} value={reportDescription} onChange={(e) => handleReportDescription(e)} />
                                                        </Grid>


                                                    </Grid>
                                                </CardContent>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} style={{ paddingLeft: 8 }}>
                                            <Grid item xs={12}>
                                                {(reportDatasource === 'azuresqldatabase' || reportDatasource === 'mssqlserver' || reportDatasource === 'mysql') &&
                                                    <>
                                                        <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                                                            <Grid item xs={12}>
                                                                <TextField spellCheck={false} inputProps={{ maxLength: 500, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' id="outlined-connection" multiline rows={3} label="Connection string" variant="outlined" style={{ width: '100%' }} value={reportConnectionString} onChange={(o) => handleReportConnectionString(o)} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                                                            <Grid item xs={12}>
                                                                <TextField spellCheck={false} inputProps={{ style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' id="outlined-script" multiline rows={12} label="Script" variant="outlined" style={{ width: '100%' }} value={reportScript} onChange={(o) => handleScript(o)} />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                }
                                                {(reportDatasource === 'REST API') &&
                                                    <>
                                                        <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                                                            <Grid item xs={1}>
                                                                <FormControl sx={{ width: '100%' }} size="small" required>
                                                                    <InputLabel style={{ fontSize: 13 }} id="demo-simple-select-Method">Method</InputLabel>
                                                                    <Select id="demo-simple-Method" label="Method" style={{ fontSize: 13 }} value={restMethod} onChange={(o) => setRestMethod(o.target.value)}>
                                                                        {
                                                                            dRestMethod.map((name) => (<MenuItem key={name} value={name} style={{ fontSize: 13 }}>{name}</MenuItem>))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <TextField required inputProps={{ style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="URL" variant="outlined" style={{ width: '100%' }} value={restUrl} onChange={(o) => setRestUrl(o.target.value)} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 15 }}>
                                                            <Grid item xs={11}>
                                                                <TextField inputProps={{ style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Body" variant="outlined" style={{ width: '100%' }} value={restUrl} onChange={(o) => setRestUrl(o.target.value)} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 15 }}>
                                                            <Grid item xs={11}>
                                                                <TextField inputProps={{ style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Authorization" variant="outlined" style={{ width: '100%' }} value={restUrl} onChange={(o) => setRestUrl(o.target.value)} />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value="2" style={{ paddingTop: 3, paddingLeft: 2, paddingRight: 6 }}>
                                        <Box sx={{ height: 375, paddingLeft: 0, paddingTop: 1 }}>
                                            <Grid container spacing={1.1} style={{ textAlign: 'left', justifyContent: 'left', paddingLeft: 8 }} >
                                                <Grid item xs={6}>
                                                    <DataGrid editMode='row' rowHeight={40} columnHeaderHeight={30} rows={parameters} columns={parameterColumns} disableColumnMenu loading={loadingParameter} getRowId={(row) => row.parameterId}
                                                        initialState={{
                                                            pagination: { paginationModel: { pageSize: 10, }, },
                                                            columns: { columnVisibilityModel: { id: false, }, },
                                                        }}
                                                        pageSizeOptions={[10]} disableRowSelectionOnClick
                                                        processRowUpdate={processRowUpdate} hideFooter={true}
                                                        sx={{ fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 } }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Card variant='outlined'>
                                                        <CardHeader style={{ paddingTop: 3, paddingBottom: 3, fontSize: 13 }} title="Properties" titleTypographyProps={{ variant: 'body1' }} />
                                                        <Divider />
                                                        <CardContent style={{ padding: 5, paddingLeft: 8, paddingTop: 8, height: 350 }}>
                                                            {(paramInfo.dataType !== '') && renderAttributeControl()}
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </TabPanel>
                                </TabContext>

                            </Collapse>
                            <CardActions disableSpacing sx={{ paddingLeft: '14px' }}>
                                <Typography style={{ fontSize: 13 }}>{(queryMessage === 'Success') ? 'Query execution time (hours:minutes:seconds): ' + queryTimeToExecute : queryMessage}</Typography>
                            </CardActions>
                        </Card >
                    }
                    {eventName === 'Share' && <ReportAccess AccessInfo={accessInfo} instance={props.instance} ShowGrantAll={true} />}
                    {(eventName === 'History') && <ReportHistory ReportInfo={reportInfo} Refresh={selectedRefresh} instance={props.instance} />}
                </Grid>
            </Grid >
            <Dialog open={openPreviewDialog} fullWidth={true} onClose={() => setOpenPreviewDialog(false)} style={{ padding: 0, overflowY: 'scroll', overflowX: 'scroll' }} maxWidth={'md'}>
                <Card>
                    <CardHeader style={{ padding: 6, paddingLeft: 10, paddingRight: 10, backgroundColor: '#e6e6e6' }} title={'Data Preview'} titleTypographyProps={{ variant: 'body1' }} action={<IconButton aria-label="Close" onClick={() => setOpenPreviewDialog(false)}><Close color='error' /></IconButton>} />
                    <Divider />
                    <CardContent style={{ overflowY: 'scroll', overflowX: 'scroll', height: 400, maxHeight: 400, padding: 0 }}>
                        {
                            renderDataset()
                        }
                    </CardContent>
                    <Alert severity='warning' icon={false} style={{ paddingTop: 3, paddingBottom: 3 }}>Only top 100 records will be visible here at max.</Alert>
                </Card>
            </Dialog>
            <Menu elevation={0} sx={{ fontSize: 13, '& .MuiPaper-root': { border: '1px solid silver' } }} anchorEl={anchorEl} open={openDMenu} onClose={(o) => setAnchorEl(null)}>
                <MenuItem style={{ fontSize: 13 }} onClick={() => handleAction('Edit')}><ListItemIcon><EditOutlined fontSize="small" color='primary' /></ListItemIcon>Edit</MenuItem>
                <MenuItem style={{ fontSize: 13 }} onClick={() => handleAction('Share')}><ListItemIcon><Share fontSize="small" color='primary' /></ListItemIcon>Share</MenuItem>
                <MenuItem style={{ fontSize: 13 }} onClick={() => handleAction('History')}><ListItemIcon><HistoryToggleOff fontSize="small" color='primary' /></ListItemIcon>History</MenuItem>
                <MenuItem style={{ fontSize: 13 }} onClick={() => handleAction('Delete')}><ListItemIcon><DeleteOutlined fontSize="small" color='error' /></ListItemIcon>Delete</MenuItem>
            </Menu>
        </>
    );

}
