import { loginRequest } from "./authConfig";
import { msalInstance } from "../index";

export async function GetJSON(URL, instance) {
    var accessToken;
    const account = instance.getActiveAccount();

    if (!account) {
        console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    else {
        const response = await instance.acquireTokenSilent({ ...loginRequest, account: account });
        accessToken = response.accessToken;
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.set('Accept', 'application/json, text/plain');
    headers.set('Content-Type', 'application/json;charset=UTF-8');

    const options = { method: "GET", headers: headers };

    return fetch(URL, options)
        .then((response, reject) => {
            if (response.ok) {
                return response.json()
            }
            else {
                if (response.status === 400) {
                    return response.json().then((errorObj) => {
                        return (Promise.reject({ error: response.status, msg: errorObj.message }))
                    }
                    );
                }
                else {
                    if (response.status === 403 || response.status === 401) {
                        return (Promise.reject({ error: response.status, msg: 'You are not  Unauthorized!!' }))
                    }
                    else {
                        return (Promise.reject({ error: response.status, msg: response.statusText }))
                    }
                }
            }
        }
        )
        .catch(error => {
            return error;
        });
}

export async function PostJSON(url, data, instance) {
    var accessToken;
    const account = instance.getActiveAccount();
    if (!account) {
        console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    else {
        const response = await instance.acquireTokenSilent({ ...loginRequest, account: account });
        accessToken = response.accessToken;
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.set('Accept', 'application/json, text/plain');
    headers.set('Content-Type', 'application/json;charset=UTF-8');

    const options = { method: "POST", headers: headers, body: JSON.stringify(data) };

    return fetch(url, options)
        .then((response, reject) => {
            if (response.ok) {
                return response.json()
            }
            else {
                console.log(response);//track error
                if (response.status === 400) {
                    return response.json().then((errorObj) => {
                        return (Promise.reject({ error: response.status, msg: errorObj.message }))
                    });
                }
                else {
                    if (response.status === 403) {
                        return (Promise.reject({ error: response.status, msg: 'You do not have permission!!' }))
                    }
                    else {
                        return (Promise.reject({ error: response.status, msg: response.statusText }))
                    }
                }
            }
        }
        )
        .catch(error => {
            return error;
        });
}

export async function PostBLOB(url, data, instance) {
    var accessToken;
    const account = instance.getActiveAccount();
    if (!account) {
        console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    else {
        const response = await instance.acquireTokenSilent({ ...loginRequest, account: account });
        accessToken = response.accessToken;
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.set("Authorization", bearer);
    headers.set('Content-Type', 'application/json;charset=UTF-8');
    const options = { method: "POST", headers: headers, body: JSON.stringify(data) };

    return fetch(url, options)
        .then((response) => {
            if (response.ok) {
                return response.blob();
            }
            else if (response.ok === false && response.status === 400) {
                return response.json();
            }
            else {
                return Promise.reject({ status: response.status, message: response.statusText });
            }
        })
        .catch(error => {
            return error;
        });
}
export async function GetBLOB(url, instance) {
    var accessToken;
    const account = instance.getActiveAccount();
    if (!account) {
        console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    else {
        const response = await instance.acquireTokenSilent({ ...loginRequest, account: account });
        accessToken = response.accessToken;
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.set("Authorization", bearer);
    headers.set('Content-Type', 'application/json;charset=UTF-8');
    const options = { method: "GET", headers: headers };

    return fetch(url, options)
        .then((response) => {
            if (response.ok) {
                return response.blob();
            }
            else if (response.ok === false && response.status === 400) {
                return response.json();
            }
            else {
                return Promise.reject({ status: response.status, message: response.statusText });
            }
        })
        .catch(error => {
            return error;
        });
}
