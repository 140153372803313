import React, { useEffect } from 'react';
import { CustomAlert } from '../Utilities/CustomAlert'
import { FormGroup, FormControlLabel, Checkbox, Typography, Avatar, TextField, Card, CardContent, CardHeader, Button, Grid, CardActions } from '@mui/material';
import { Person, } from '@mui/icons-material';
import { BASE_URL } from '../Utilities/APIURLs';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux'
import { IsNullOrEmptyOrUndefined, ValidateInput, ValidateLoginInput, ValidateEmail } from '../Utilities/String';
import { PostJSON } from '../Utilities/APICall'

export function UserInsert(props) {

    const userinfo = useSelector((state) => state.userinfo);
    const dateformat = 'DD MMM YY HH:mm:ss';

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [emailId, setEmailId] = React.useState('');
    const [loginId, setLoginId] = React.useState('');
    const [employeeId, setEmployeeId] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState('');
    const [active, setActive] = React.useState(false);
    const [role, setRole] = React.useState('');

    useEffect(() => {
        if (props.IsEdit && IsNullOrEmptyOrUndefined(props.UserInfo.firstName) === false) {
            setFirstName(props.UserInfo.firstName)
            setLastName(props.UserInfo.lastName)
            setLoginId(props.UserInfo.loginId)
            setEmailId(props.UserInfo.emailId)
            setEmployeeId(props.UserInfo.employeeId)
            setActive(props.UserInfo.active)
            setSelectedId(props.UserInfo.Id)
        }
    }, [props.IsEdit]);


    const insertUser = () => {
        setLoading(true)
        var datamodel = { "FirstName": firstName, "LastName": lastName, "EmailId": emailId, "LoginId": loginId, "EmployeeId": employeeId, "Active": active, "ModifiedBy": userinfo.userid, 'Role': role }
        var url = BASE_URL + 'UserMaster/Insert'
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message.length > 0) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    props.Refresh();
                    handleResetUser()
                }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const updateUser = () => {
        setLoading(true)
        var datamodel = { "Id": selectedId, "FirstName": firstName, "LastName": lastName, "EmailId": emailId, "LoginId": loginId, "EmployeeId": employeeId, "ModifiedBy": userinfo.userid, "Active": active, }
        var url = BASE_URL + 'UserMaster/Update'
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message.length > 0) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    props.Refresh();
                    handleResetUser()
                }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const handleAddUser = () => {
        var isvalidate = validateUserForm()
        if (isvalidate === false) return;
        if (props.IsEdit)
            updateUser()
        else
            insertUser();
    }
    const validateUserForm = () => {
        if (firstName.length > 50 || firstName.length <= 0 || ValidateInput(firstName) === false) {
            CustomAlert('First Name should match char length (> 0 and < 50) char and no special char', 'error')
            return false;
        }
        if (lastName.length > 50 || lastName.length <= 0 || ValidateInput(lastName) === false) {
            CustomAlert('Last Name should match char length (> 0 and < 50) char and no special char', 'error')
            return false;
        }
        if (loginId.length > 50 || loginId.length < 1 || ValidateLoginInput(loginId) === false) {
            CustomAlert('LoginId should match char length (> 0 and < 50) with no special char', 'error')
            return false;
        }
        if (IsNullOrEmptyOrUndefined(emailId) === false) {
            var isValid = ValidateEmail(emailId);
            if (isValid === false) {
                CustomAlert('Please enter valid email address', 'error')
                return false
            }
        }
    }
    const handleResetUser = () => {
        setFirstName('')
        setLastName('')
        setEmailId('')
        setLoginId('')
        setRole('')
        setEmployeeId('')
        setActive(false)
        props.onClose()
    }
    return (
        <>

            <Card variant='outlined' style={{ textAlign: 'left', justifyContent: 'left', height: window.innerHeight - 136 }}>
                <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 5, backgroundColor: '#eef2f6' }} title={<Typography style={{ fontSize: 14 }}>Add User</Typography>} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}><Person /></Avatar>} />
                <CardContent sx={{ padding: '10px' }}>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                        <Grid item xs={6}>
                            <TextField required inputProps={{ maxLength: 50, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="FirstName" variant="outlined" style={{ width: '100%' }} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required inputProps={{ maxLength: 50, maxLength: 100, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="LastName" variant="outlined" style={{ width: '100%' }} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: '5px' }}>
                        <Grid item xs={6}>
                            <TextField required inputProps={{ maxLength: 50, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="LoginId" variant="outlined" style={{ width: '100%' }} value={loginId} onChange={(e) => setLoginId(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField inputProps={{ maxLength: 50, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="EmployeeId" variant="outlined" style={{ width: '100%' }} value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: '5px' }}>
                        <Grid item xs={6}>
                            <TextField inputProps={{ maxLength: 100, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="EmailId" variant="outlined" style={{ width: '100%' }} type="email" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup><FormControlLabel control={<Checkbox checked={active} onChange={(o) => setActive(o.target.checked)} />} label={<Typography style={{ fontSize: 13 }}>Active</Typography>} /></FormGroup>
                        </Grid>
                    </Grid>

                </CardContent>
                <CardActions>
                    <Grid container spacing={1}>
                        <Grid item>
                            <LoadingButton style={{ textTransform: 'none', width: 100 }} variant='outlined' disableElevation size='small' loading={loading} onClick={handleAddUser}>Save</LoadingButton>
                        </Grid>
                        <Grid item>
                            <Button style={{ textTransform: 'none', width: 100 }} variant='outlined' disableElevation size='small' onClick={handleResetUser}>Close</Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>

        </>
    );
}