import React, { useEffect, useRef } from 'react';
import { CustomAlert } from '../Utilities/CustomAlert'
import { CircularProgress, Box, Divider, CardHeader, Avatar, Collapse, Grid, Typography, Card, CardContent, Tooltip } from '@mui/material';
import { PublishedWithChanges, Today, Insights, ErrorOutline, TaskAltOutlined } from '@mui/icons-material';
import { BASE_URL } from '../Utilities/APIURLs';
import { GetJSON } from '../Utilities/APICall';
import { LocalTimeZone } from '../Utilities/Date';
// import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux'
import { EncryptText, IsNullOrEmptyOrUndefined } from '../Utilities/String';
export function UserInsight(props) {

    const downloadreport = useSelector((state) => state.downloadreport);
    const dateformat = 'DD MMM YY HH:mm:ss';
    const [reportType, setReportType] = React.useState({});
    const [successRateToday, setSuccessRateToday] = React.useState({});
    const [successRateYest, setSuccessRateYest] = React.useState({});
    const [xLabels, setXLabels] = React.useState([]);
    const [xData, setXData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [schedules, setSchedules] = React.useState([]);
    const [transaction, setTransaction] = React.useState([]);

    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (props.UserId > 0 && dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            getInsight()
            getSchedule()
            getHistory()
        }
    }, [props.UserId]);

    useEffect(() => {
        if (IsNullOrEmptyOrUndefined(downloadreport.date) === false) {
            getHistory()
            downloadreport.date = ''
        }
    }, [downloadreport.date]);

    /** API Calls */
    const getInsight = () => {
        setLoading(true)
        var url = BASE_URL + 'Insight/GetByUserId?Timezone=' + LocalTimeZone()
        GetJSON(url, props.instance).then(data => {
            setLoading(false)

            setXLabels(data.historyByDate.map(k => k.label))
            setXData(data.historyByDate.map(k => k.value))

            var type = data.history.filter((o) => o.category === 'ReportType')
            if (type.length > 0) {
                var arrsch = type.filter((o) => o.type === 'Schedule')
                var sch = arrsch.length > 0 ? arrsch[0].count : 0
                var arrint = type.filter((o) => o.type === 'Interactive')
                var int = arrint.length > 0 ? arrint[0].count : 0
                setReportType({ Schedule: sch, Interactive: int })
            }
            else {
                setReportType({ Schedule: 0, Interactive: 0 })
            }
            var sr = data.history.filter((o) => o.category === 'Today')
            if (sr.length > 0) {
                var arrsuc = sr.filter((o) => o.type === 'success')
                var suc = (arrsuc.length > 0) ? arrsuc[0].count : 0
                var arrerr = sr.filter((o) => o.type === 'error')
                var err = arrerr.length > 0 ? arrerr[0].count : 0
                setSuccessRateToday({ Success: suc, Error: err })
            }
            else {
                setSuccessRateToday({ Success: 0, Error: 0 })
            }
            var pb = data.history.filter((o) => o.category === 'Yesterday')
            if (pb.length > 0) {
                var arrpub = pb.filter((o) => o.type === 'success')
                var pub = arrpub.length > 0 ? arrpub[0].count : 0
                var arrunb = pb.filter((o) => o.type === 'error')
                var unb = arrunb.length > 0 ? arrunb[0].count : 0
                setSuccessRateYest({ Success: pub, Error: unb })
            }
            else {
                setSuccessRateYest({ Success: 0, Error: 0 })
            }
        })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const getSchedule = () => {
        setLoading(true)
        var url = BASE_URL + 'ReportSetting/GetRecurrenceByUserId';
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                setSchedules(data)
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const getHistory = () => {
        setLoading(true)
        var url = BASE_URL + 'History/GetByUserId'
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                setTransaction(data)
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    /** API Calls End */

    const schCol = [
        { field: 'id', headerName: 'Id', width: 90 },
        {
            field: 'frequency', headerName: '', width: 30, minWidth: 30, editable: false, align: 'left',
            renderCell: (cell) => {
                return (<><Avatar sx={{ marginTop: .4, width: 20, height: 20, bgcolor: 'orange' }}><Typography style={{ fontSize: 13 }}>{cell.row.frequency.substring(0, 1)}</Typography></Avatar></>);
            },
        },
        {
            field: 'userInput', headerName: '', editable: false, width: 100,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 3 }}>{cell.row.userinput}</Typography></>);
            },
        },
        {
            field: 'reportName', headerName: '', flex: 1,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 3 }}>{cell.row.reportName}</Typography></>);
            },
        },
    ];
    const colHistory = [
        { field: 'id', headerName: 'Id', width: 90 },
        {
            field: 'status', headerName: '', width: 40, minWidth: 40, editable: false, align: 'center',
            renderCell: (cell) => {
                return (<div style={{ marginTop: '3px' }}>{cell.row.status.toLowerCase() === 'success' ? <TaskAltOutlined fontSize="small" color="success" /> : cell.row.status.toLowerCase() === 'inprogress' ? <CircularProgress size={17} /> : <ErrorOutline fontSize="small" color="error" />}</div>);
            },
        },
        {
            field: 'frequency', headerName: '', width: 80, editable: false, align: 'left',
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 3 }}>{cell.row.frequency}</Typography></>);
            },
        },
        {
            field: 'started', headerName: '', editable: false, width: 130,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 3 }}>{dayjs(cell.row.started).utc('z').local().format(dateformat)}</Typography></>);
            },
        },
        {
            field: 'reportName', headerName: 'Name', editable: false, flex: 1,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 3 }}>{cell.row.reportName}</Typography></>);
            },
        },

    ];
    return (
        <>
            <Card variant="outlined" style={{ height: window.innerHeight - 80 }}>
                {/* <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title="Insights" titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}> <Insights fontSize='20' /></Avatar>} /> */}
                <Collapse in={true}>
                    <CardContent>

                        <div style={{ width: '100%', height: 120, marginBottom: 10, borderRadius: 5, background: 'linear-gradient(250.38deg, rgb(230, 244, 255) 2.39%, rgb(105, 177, 255) 34.42%, rgb(22, 119, 255) 60.95%, rgb(9, 88, 217) 74.83%, rgb(0, 44, 140) 74.37%)' }}>
                            <CardContent>
                                <Typography style={{ color: 'white', fontSize: 25, marginBottom: 20 }}>Welcome to Accelerator: A reporting and scheduling platform</Typography>
                                <Typography style={{ color: 'white', fontSize: 15 }}>The purpose of a tool to build sql report with huge amount of data and optionally schedule as per requirement.</Typography>

                            </CardContent>
                        </div>

                        <Grid container spacing={1} style={{ paddingBottom: 8, justifyContent: 'center' }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={4}>
                                <Card variant="outlined" style={{ backgroundColor: '#fdecce', border: 0, height: 120 }}>
                                    <CardContent style={{ paddingBottom: 2, paddingTop: 5, textAlign: 'center', justifyContent: 'center' }}>
                                        <Today sx={{ fontSize: 30, color: '#ffae1f' }} />
                                        <Typography sx={{ fontSize: 14, color: '#ffae1f' }}>Today's Execution</Typography>
                                    </CardContent>
                                    <CardContent style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'center', justifyContent: 'center' }}>
                                        <Grid container spacing={2} >
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: 14, color: '#ffae1f' }}>Successful</Typography>
                                                <Typography variant='h6' sx={{ color: '#ffae1f' }}>{successRateToday.Success}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: 14, color: '#ffae1f' }}>Failed</Typography>
                                                <Typography variant='h6' sx={{ color: '#ffae1f' }}>{successRateToday.Error}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card variant="outlined" style={{ backgroundColor: '#ccfff5', border: 0, height: 120 }}>
                                    <CardContent style={{ paddingBottom: 2, paddingTop: 5, textAlign: 'center', justifyContent: 'center' }}>
                                        <Today sx={{ fontSize: 30, color: '#13deb9' }} />
                                        <Typography sx={{ fontSize: 14, color: '#13deb9' }}>Yesterday's Execution</Typography>
                                    </CardContent>
                                    <CardContent style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'center', justifyContent: 'center' }}>
                                        <Grid container spacing={2} >
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: 14, color: '#13deb9' }}>Successful</Typography>
                                                <Typography variant='h6' sx={{ color: '#13deb9' }}>{successRateYest.Success}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: 14, color: '#13deb9' }}>Failed</Typography>
                                                <Typography variant='h6' sx={{ color: '#13deb9' }}>{successRateYest.Error}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card variant="outlined" style={{ backgroundColor: '#ecf2ff', border: 0, height: 120 }}>
                                    <CardContent style={{ paddingBottom: 2, paddingTop: 5, textAlign: 'center', justifyContent: 'center' }}>
                                        <PublishedWithChanges sx={{ fontSize: 30, color: '#673ab7' }} />
                                        <Typography sx={{ fontSize: 14, color: '#673ab7' }}>Execution Type</Typography>
                                    </CardContent>
                                    <CardContent style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'center', justifyContent: 'center' }}>
                                        <Grid container spacing={2} >
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: 14, color: '#673ab7' }}>Manual</Typography>
                                                <Typography variant='h6' sx={{ color: '#673ab7' }}>{reportType.Interactive}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: 14, color: '#673ab7' }}>Scheduled</Typography>
                                                <Typography variant='h6' sx={{ color: '#673ab7' }}>{reportType.Schedule}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={6}>
                                {/* <div style={{ height: 300, width: "100%" }}>
                                    <LineChart
                                        series={[{ data: xData, label: 'Execution count', area: true, showMark: false, color: '#91beee' }]}
                                        xAxis={[{ scaleType: 'point', data: xLabels }]}
                                        sx={{ [`& .${lineElementClasses.root}`]: { display: 'none', }, }}
                                        margin={{ left: 30, right: 25 }}
                                    />
                                </div> */}
                                <Card variant='outlined' style={{ width: '100%' }}>
                                    <CardHeader title={<Typography style={{ fontSize: 13 }}>My Scheduled Report</Typography>} style={{ padding: 4, paddingLeft: 10 }} />
                                    <Divider />
                                    <Box sx={{ height: 310, width: '100%' }}>
                                        <DataGrid rowHeight={27} columnHeaderHeight={0} rows={schedules} columns={schCol} disableColumnMenu loading={loading}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 100, }, },
                                                columns: { columnVisibilityModel: { id: false }, },
                                            }}
                                            pageSizeOptions={[100]} disableRowSelectionOnClick hideFooter
                                            sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                                        />
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card variant='outlined' style={{ width: '100%' }}>
                                    <CardHeader title={<Typography style={{ fontSize: 13 }}>Recent Transaction</Typography>} style={{ padding: 4, paddingLeft: 10 }} />
                                    <Divider />
                                    <Box sx={{ height: 310, width: '100%' }}>
                                        <DataGrid rowHeight={27} columnHeaderHeight={0} rows={transaction} columns={colHistory} disableColumnMenu loading={loading}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 100, }, },
                                                columns: { columnVisibilityModel: { id: false }, },
                                            }}
                                            pageSizeOptions={[100]} disableRowSelectionOnClick hideFooter
                                            sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                                        />
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>

        </>
    );

}
