import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CustomAlert } from '../Utilities/CustomAlert'
import { MenuItem, Tab, Menu, ListItemIcon, ListItemText, Avatar, Box, IconButton, Card, CardContent, CardHeader, Button, Grid, Tooltip, Dialog } from '@mui/material';
import { Edit, Person, ExpandMore, PersonAdd, KeyOutlined } from '@mui/icons-material';
import { BASE_URL } from '../Utilities/APIURLs';
import csv from '../Images/csv.svg'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useSelector } from 'react-redux'
import { IsNullOrEmptyOrUndefined, ValidateInput, ValidateLoginInput, ValidateEmail } from '../Utilities/String';
import { GetBLOB, GetJSON, PostJSON } from '../Utilities/APICall';
import { UserInsert } from './UserInsert';
import { DeveloperAccess } from './DeveloperAccess';
import { UserAssignReport } from './UserAssignReport';

export function UserMaster(props) {
    const userinfo = useSelector((state) => state.userinfo);
    const [users, setUsers] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);
    const [deactiveUsers, setDeactiveUsers] = useState([]);
    const [activeUserCaption, setActiveUserCaption] = useState('');
    const [deactiveUserCaption, setDeactiveUserCaption] = useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [emailId, setEmailId] = React.useState('');
    const [loginId, setLoginId] = React.useState('');
    const [employeeId, setEmployeeId] = React.useState('');
    const [userId, setUserId] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [isUserEdit, setIsUserEdit] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState('');
    const [active, setActive] = React.useState(false);
    const [userDialog, setUserDialog] = React.useState(false);
    const [developerDialog, setDeveloperDialog] = React.useState(false);
    const [assignedDialog, setAssignedDialog] = React.useState(false);

    const [userInfo, setUserInfo] = React.useState({});

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openDMenu = Boolean(anchorEl);
    const [downloading, setDownloading] = useState(false);
    const [tabValue, setTabValue] = React.useState('1');
    const [role, setRole] = React.useState('');
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            getUsers();
        }
    }, []);

    /** API Calls */
    const getUsers = () => {
        setUsers([])
        setLoading(true)
        var url = BASE_URL + 'UserMaster/GetAllByReportCount'
        GetJSON(url, props.instance).then(data => {
            setLoading(false)
            setUsers(data);
            var au = data.filter((o) => o.active === true)
            var du = data.filter((o) => o.active === false)

            setActiveUsers(au);
            setDeactiveUsers(du);

            setActiveUserCaption('Active Users (' + au.length + ')')
            setDeactiveUserCaption('Deactive Users (' + du.length + ')')
        })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e, 'error');
            });

    }
    const insertUser = () => {
        setLoading(true)
        var datamodel = { "FirstName": firstName, "LastName": lastName, "EmailId": emailId, "LoginId": loginId, "EmployeeId": employeeId, "Active": active, "ModifiedBy": userinfo.userid, 'Role': role }
        var url = BASE_URL + 'UserMaster/Insert'
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message.length > 0) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    setUsers(data.data);

                    var au = data.data.filter((o) => o.active === true)
                    var du = data.data.filter((o) => o.active === false)

                    setActiveUsers(au);
                    setDeactiveUsers(du);

                    setActiveUserCaption('Active Users (' + au.length + ')')
                    setDeactiveUserCaption('Deactive Users (' + du.length + ')')
                    handleResetUser()
                }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const updateUser = () => {
        setLoading(true)
        var datamodel = { "Id": selectedId, "FirstName": firstName, "LastName": lastName, "EmailId": emailId, "LoginId": loginId, "EmployeeId": employeeId, "Active": active, "ModifiedBy": userinfo.userid, 'Role': role }
        var url = BASE_URL + 'UserMaster/Update'
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message.length > 0) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    setUsers(data.data);

                    var au = data.data.filter((o) => o.active === true)
                    var du = data.data.filter((o) => o.active === false)

                    setActiveUsers(au);
                    setDeactiveUsers(du);

                    setActiveUserCaption('Active Users (' + au.length + ')')
                    setDeactiveUserCaption('Deactive Users (' + du.length + ')')
                    handleResetUser()

                }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const deleteUser = (Id) => {
        setLoading(true)
        var url = BASE_URL + 'UserMaster/Delete?Id=' + Id + '&UserId=' + userinfo.userid;
        GetJSON(url, props.instance)
            .then(response => {
                if (!response.ok) { throw new Error('Network response was not ok'); }
                return response.json();
            })
            .then((data) => {
                setLoading(false)
                setUsers(data);
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e, 'error');
            });
    }
    const handleExport = () => {
        setDownloading(true)
        var url = BASE_URL + 'UserMaster/DownloadUsers'
        GetBLOB(url, props.instance)
            .then((data) => {
                setDownloading(false)
                if (IsNullOrEmptyOrUndefined(data.message) === false) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = url;
                    let fileName = ''
                    fileName = 'Accelerator User.csv';
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }
            })
            .catch((e) => {
                setDownloading(false)
                CustomAlert(e.message, 'error');
            });
    }
    /** API Calls End */
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleAddUser = () => {
        var isvalidate = validateUserForm()
        if (isvalidate === false) return;
        if (isUserEdit)
            updateUser()
        else
            insertUser();
    }
    const validateUserForm = () => {
        if (firstName.length > 50 || firstName.length <= 0 || ValidateInput(firstName) === false) {
            CustomAlert('First Name should not be more than 50 char and no special char', 'error')
            return false;
        }
        if (lastName.length > 50 || lastName.length <= 0 || ValidateInput(lastName) === false) {
            CustomAlert('Last Name should not be more than 50 char and no special char', 'error')
            return false;
        }
        if (loginId.length > 50 || loginId.length < 4 || ValidateLoginInput(loginId) === false) {
            CustomAlert('LoginId should match char length (< 51 and > 3) with no special char', 'error')
            return false;
        }
        if (IsNullOrEmptyOrUndefined(emailId) === false) {
            var isValid = ValidateEmail(emailId);
            if (isValid === false) {
                CustomAlert('Please enter valid email address', 'error')
                return false
            }
        }
    }
    const handleEditUser = (Id) => {
        var user = users.filter((row) => row.id === Id)[0];
        setUserInfo({ firstName: user.firstName, lastName: user.lastName, loginId: user.loginId, emailId: user.emailId, employeeId: user.employeeId, active: user.active, Id: Id })
        setIsUserEdit(true);
        setSelectedId(Id);
        setUserDialog(true);
    }
    const handleResetUser = () => {
        setIsUserEdit(false);
        setSelectedId('');
        setUserDialog(false);
    }
    const ShowAssignReport = (fname, lname, userid) => {
        setFirstName(fname);
        setLastName(lname);
        setAssignedDialog(true);
        setUserId(userid)
    }
    const handleDeleteUser = (Id) => {
        if (window.confirm("Are you sure want to your deleted?") === true) {
            deleteUser(Id);
        }
    }
    const columnsUser = [
        { field: 'id', headerName: 'Id', width: 60, sortable: true },
        { field: 'firstName', headerName: 'FirstName', width: 100, sortable: true },
        { field: 'lastName', headerName: 'LastName', width: 100, sortable: true },
        { field: 'loginId', headerName: 'LoginId', width: 190, sortable: true },
        { field: 'employeeId', headerName: 'EmployeeId', width: 100, sortable: true },
        { field: 'emailId', headerName: 'EmailId', flex: 1, sortable: true },
        {
            field: 'edit', headerName: '', width: 100,
            renderCell: (cell) => {
                return (
                    <>
                        <Tooltip title='Edit'><IconButton size='small' aria-label="edit" onClick={() => handleEditUser(cell.row.id)}><Edit sx={{ fontSize: 20 }} color='primary' /></IconButton></Tooltip>
                        <Tooltip title='Assigned Report'><IconButton size='small' aria-label="Show" onClick={() => ShowAssignReport(cell.row.firstName, cell.row.lastName, cell.row.id)}><Avatar sx={{ width: 22, height: 22, bgcolor: '#ff5722', fontSize: 13 }}>{cell.row.count}</Avatar></IconButton></Tooltip>
                    </>
                );
            },
        },
    ]

    const handleImport = () => { }
    const onCloseDialog = () => {
        setUserInfo({});
        setIsUserEdit(false)
        setUserDialog(false);
        setDeveloperDialog(false);
        setAssignedDialog(false)
    }

    return (
        <>

            <Card variant="outlined" style={{ marginTop: 10, marginBottom: 10, border: 0 }}>
                <CardHeader style={{ paddingTop: 6, paddingBottom: 6, paddingLeft: 5 }} title="User Management" titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}> <Person /></Avatar>}
                    action={
                        <div style={{ paddingRight: 2, paddingTop: 3 }}>
                            <Button variant="outlined" size='small' sx={{ textTransform: 'none', height: 30 }} endIcon={<KeyOutlined />} onClick={() => setDeveloperDialog(true)}>Developer Permission</Button>{' '}
                            <Button variant="outlined" size='small' sx={{ textTransform: 'none', height: 30 }} endIcon={<PersonAdd />} onClick={() => setUserDialog(true)}>Add User</Button>{' '}
                            <Button variant="outlined" disabled={downloading} size='small' sx={{ textTransform: 'none', height: 30 }} endIcon={<ExpandMore />} onClick={(e) => setAnchorEl(e.currentTarget)}>Export</Button>
                        </div>
                    } />
                <CardContent style={{ padding: 10, paddingLeft: 5 }}>
                    <Grid container spacing={1}>
                        {/* <Grid item xs={4}>
                            <Card variant='outlined' style={{ textAlign: 'left', justifyContent: 'left', height: window.innerHeight - 136 }}>
                                <CardHeader style={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 5, backgroundColor: '#eef2f6' }} title={
                                    <>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Tooltip title='Save'><IconButton size='small' disabled={loading} onClick={handleAddUser}>{loading ? <CircularProgress size={24} /> : <Save color='primary' />}</IconButton></Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title='Cancel'><IconButton size='small' onClick={handleResetUser}><HighlightOff sx={{ color: 'red' }} /></IconButton></Tooltip>
                                            </Grid>
                                        </Grid>
                                    </>
                                } />


                                <CardContent sx={{ padding: '5px' }}>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: 5 }}>
                                        <Grid item xs={6}>
                                            <TextField required inputProps={{ maxLength: 50, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="FirstName" variant="outlined" style={{ width: '100%' }} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField required inputProps={{ maxLength: 50, maxLength: 100, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="LastName" variant="outlined" style={{ width: '100%' }} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: '5px' }}>
                                        <Grid item xs={6}>
                                            <TextField required inputProps={{ maxLength: 50, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="LoginId" variant="outlined" style={{ width: '100%' }} value={loginId} onChange={(e) => setLoginId(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField inputProps={{ maxLength: 50, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="EmployeeId" variant="outlined" style={{ width: '100%' }} value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: '5px' }}>
                                        <Grid item xs={6}>
                                            <TextField inputProps={{ maxLength: 100, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="EmailId" variant="outlined" style={{ width: '100%' }} type="email" value={emailId} onChange={(e) => setEmailId(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormGroup><FormControlLabel control={<Checkbox checked={active} onChange={(o) => setActive(o.target.checked)} />} label={<Typography style={{ fontSize: 13 }}>Active</Typography>} /></FormGroup>
                                        </Grid>
                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Card variant='outlined'>
                                <TabContext value={tabValue} style={{}}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleTabChange} aria-label="Users" style={{ height: 37, minHeight: 37, backgroundColor: '#eef2f6' }}>
                                            <Tab style={{ textTransform: 'none', paddingTop: 4 }} label={activeUserCaption} value="1" />
                                            <Tab style={{ textTransform: 'none', paddingTop: 4 }} label={deactiveUserCaption} value="2" />
                                        </TabList>
                                    </Box>

                                    <TabPanel value="1" style={{ padding: 0 }}>
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid rowHeight={30} columnHeaderHeight={37} rows={activeUsers} columns={columnsUser} disableColumnMenu loading={loading}
                                                initialState={{
                                                    pagination: { paginationModel: { pageSize: 10, }, },
                                                    columns: { columnVisibilityModel: { id: false }, },
                                                }}
                                                pageSizeOptions={[10]} disableRowSelectionOnClick
                                                sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                                            />
                                        </Box>
                                    </TabPanel>

                                    <TabPanel value="2" style={{ padding: 0 }}>
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid rowHeight={30} columnHeaderHeight={37} rows={deactiveUsers} columns={columnsUser} disableColumnMenu loading={loading}
                                                initialState={{
                                                    pagination: { paginationModel: { pageSize: 10, }, },
                                                    columns: { columnVisibilityModel: { id: false }, },
                                                }}
                                                pageSizeOptions={[10]} disableRowSelectionOnClick
                                                sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                                            />
                                        </Box>
                                    </TabPanel>

                                </TabContext>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Menu elevation={0} sx={{ fontSize: 13, '& .MuiPaper-root': { border: '1px solid silver' }, '& .MuiTypography-root': { fontSize: 13 } }} anchorEl={anchorEl} open={openDMenu} onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={() => handleExport()}>
                    <ListItemIcon><img src={csv} style={{ width: 22, height: 22, fill: "red", stroke: "yellow" }} /></ListItemIcon><ListItemText>Export to CSV</ListItemText>
                </MenuItem>
                {/* <MenuItem disabled onClick={() => handleImport()} >
                    <ListItemIcon><FileUpload fontSize="small" color='primary' /></ListItemIcon><ListItemText >Import CSV file</ListItemText>
                </MenuItem> */}
            </Menu>

            <Dialog fullWidth={true} maxWidth={'sm'} onClose={onCloseDialog} open={userDialog} disableEscapeKeyDown={true}>
                <UserInsert instance={props.instance} Refresh={() => getUsers()} onClose={onCloseDialog} UserInfo={userInfo} IsEdit={isUserEdit} />
            </Dialog>
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={onCloseDialog} open={developerDialog} disableEscapeKeyDown={true}>
                <DeveloperAccess instance={props.instance} onClose={onCloseDialog} />
            </Dialog>
            <Dialog fullWidth={true} maxWidth={'md'} onClose={onCloseDialog} open={assignedDialog} disableEscapeKeyDown={true}>
                <UserAssignReport instance={props.instance} onClose={onCloseDialog} UserName={firstName + ' ' + lastName} UserId={userId} />
            </Dialog>


        </>
    );

}
