import dayjs from 'dayjs';

export const AddMinusDays = (date, days, mode) => {
    var result = new Date(date);
    if (mode === '+') {
        result.setDate(result.getDate() + days);
    }
    else {
        result.setDate(result.getDate() - days);
    }
    return result;
}
export const IsToday = (Value) => {
    const today = new Date()
    return Value.getDate() === today.getDate() && Value.getMonth() === today.getMonth() && Value.getFullYear() === today.getFullYear()
}
export const IsDateEqual = (dt1, dt2) => {
    var result = dt1.getDate() === dt2.getDate() && dt1.getMonth() === dt2.getMonth() && dt1.getFullYear() === dt2.getFullYear()
    return result;
}

export const ConvertNullToEmpty = (value) => {
    return (value === null || value === undefined) ? '' : value;
}
export const ToDateFormat = (date, format) => {
    let str = ''
    if (date !== undefined && date !== null && date !== '') {
        var myDate = new Date(date);
        let newformat = '';
        switch (format) {
            case "D MMM YYYY": {
                const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][myDate.getMonth()];
                str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
                break;
            }
            case "D MMM YYYY HH:MM": {
                const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][myDate.getMonth()];
                var hours = myDate.getHours();
                var minutes = myDate.getMinutes();

                newformat = hours >= 12 ? 'PM' : 'AM';// Check whether AM or PM                
                hours = hours % 12;// Find current hour in AM-PM Format                
                hours = hours ? hours : 12;// To display "0" as "12"
                minutes = minutes.toString().padStart(2, '0');
                str = myDate.getDate() + ' ' + month + ' ' + myDate.getFullYear() + ' ' + hours + ':' + minutes + ' ' + newformat
                break;
            }
            case "D MMM YYYY HH:MM:SS": {
                const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][myDate.getMonth()];
                let hours = myDate.getHours();
                let minutes = myDate.getMinutes();

                newformat = hours >= 12 ? 'PM' : 'AM';// Check whether AM or PM                
                hours = hours % 12;// Find current hour in AM-PM Format                
                hours = hours ? hours : 12;// To display "0" as "12"
                minutes = minutes.toString().padStart(2, '0');
                var seconds = myDate.getSeconds().toString().padStart(2, '0');
                str = myDate.getDate() + ' ' + month + ' ' + myDate.getFullYear() + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + newformat
                break;
            }
            case "D MMMM YYYY": {
                const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][myDate.getMonth()];
                str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
                break;
            }
            case "D MMMM YYYY HHMM": {
                const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][myDate.getMonth()];
                str = myDate.getDate() + " " + month + " " + myDate.getFullYear() + " " + myDate.getHours() + ":" + myDate.getMinutes();
                break;
            }
            case "D/M/YYYY": {
                str = myDate.getDate() + "/" + (myDate.getMonth() + 1) + "/" + myDate.getFullYear();
                break;
            }
            case "D": {
                str = myDate.getDate();
                break;
            }
            default:
                str = ''
                break;
        }
    }
    return str;
}
export const CalculateAge = (dateOfBirth) => {
    var now = new Date();
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var dob = dateOfBirth

    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";
    var dateAge = ''
    var monthAge = ''

    var yearAge = yearNow - yearDob;

    if (monthNow >= monthDob)
        monthAge = monthNow - monthDob;
    else {
        yearAge--;
        monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob)
        dateAge = dateNow - dateDob;
    else {
        monthAge--;
        dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
        }
    }

    age = { years: yearAge, months: monthAge, days: dateAge };

    if (age.years > 1) yearString = " years";
    else yearString = " year";
    if (age.months > 1) monthString = " months";
    else monthString = " month";
    if (age.days > 1) dayString = " days";
    else dayString = " day";


    if ((age.years > 0) && (age.months > 0) && (age.days > 0))
        ageString = age.years + yearString + ", " + age.months + monthString + ", and " + age.days + dayString;
    else if ((age.years === 0) && (age.months === 0) && (age.days > 0))
        ageString = "Only " + age.days + dayString;
    else if ((age.years > 0) && (age.months === 0) && (age.days === 0))
        ageString = age.years + yearString;
    else if ((age.years > 0) && (age.months > 0) && (age.days === 0))
        ageString = age.years + yearString + " and " + age.months + monthString;
    else if ((age.years === 0) && (age.months > 0) && (age.days > 0))
        ageString = age.months + monthString + " and " + age.days + dayString;
    else if ((age.years > 0) && (age.months === 0) && (age.days > 0))
        ageString = age.years + yearString + " and " + age.days + dayString;
    else if ((age.years === 0) && (age.months > 0) && (age.days === 0))
        ageString = age.months + monthString;
    else ageString = "Oops! Could not calculate age!";

    return ageString;
}
export const BirthOfYearValidation = (year) => {
    var result = true;
    var text = /^[0-9]+$/;
    try {
        if (year.toString().length === 4) {
            if (year !== 0) {
                if ((year !== '') && (!text.test(year))) {
                    result = false;
                }
                if (year.toString().length !== 4) {
                    result = false;
                }
                var current_year = new Date().getFullYear();
                if ((Number(year) < 1920) || (Number(year) > current_year)) {
                    result = false;
                }
            }
        }
        else {
            result = false;
        }
    }
    catch {
        result = false
    }
    return result;
}
export const YearValidation = (year) => {
    var result = true;
    var text = /^[0-9]+$/;
    try {
        if (year.toString().length === 4) {
            if (year !== 0) {
                if ((year !== '') && (!text.test(year))) {
                    result = false;
                }
                if (year.toString().length !== 4) {
                    result = false;
                }
                var current_year = new Date().getFullYear();
                if ((Number(year) > current_year)) {
                    result = false;
                }
            }
        }
        else {
            result = false;
        }
    }
    catch {
        result = false
    }
    return result;
}
export const Display12HourFormat = (dt) => {
    var date = new Date(dt);
    var hours = date.getHours();
    var minutes = date.getMinutes();

    // Check whether AM or PM
    var newformat = hours >= 12 ? 'PM' : 'AM';

    // Find current hour in AM-PM Format
    hours = hours % 12;

    // To display "0" as "12"
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return hours + ':' + minutes + ' ' + newformat;
}
export const MonthDiff = (dateFrom, dateTo) => {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}
export const DayDiff = (dateStart, dateEnd) => {
    var time_difference = dateEnd.getTime() - dateStart.getTime();
    var days_difference = time_difference / (1000 * 60 * 60 * 24);
    return Math.ceil(days_difference);
}
export const TimeDiff = (dt) => {
    const past = dayjs(dt);
    const today = dayjs();

    let month = today.diff(past, 'month');
    let yr = today.diff(past, 'year');

    let mnt = month - (yr * 12);
    return yr + ' years and ' + mnt + ' months.'
}
export const TimeDiffInHHMMSS = (start, end) => {
    const tStart = dayjs(start);
    const tEnd = dayjs(end);


    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(tEnd - tStart);

    // Convert milliseconds to hours, minutes, and seconds
    let hour = Math.floor(timeDifference / 3600000);
    let minute = Math.floor((timeDifference % 3600000) / 60000);
    let second = Math.floor((timeDifference % 60000) / 1000);

    if (hour <= 0 && minute <= 0 && second <= 0) {
        second = 1;
    }

    let tm = '';
    if (hour > 0) tm = hour + 'h '
    if (minute > 0) tm = tm + minute + 'm '
    if (second > 0) tm = tm + second + 's'
    return tm.trim();
}

export const LocalTimeZone = () => {
    const today = new Date();
    var tz = today.toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
    return tz;
}