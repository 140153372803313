import './App.css';
import React, { Suspense, useEffect, useRef } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import { Fab, CircularProgress, Card, CardContent, Typography, CardHeader, Divider } from '@mui/material';
import { Speed } from '@mui/icons-material';

import { Home } from './Components/Home'
import { toast, ToastContainer } from 'react-toastify';
import './Styles/Toastify.css'
import { ReportMaster } from './Components/ReportMaster';
import { UserMaster } from './UserManagement/UserMaster';
import { ReportLookup } from './Components/ReportLookup';
import { useSelector, useDispatch } from 'react-redux'
import { BASE_URL } from './Utilities/APIURLs';
import { CustomAlert } from './Utilities/CustomAlert'
import { IsNullOrEmptyOrUndefined } from './Utilities/String';
import { loginRequest, msalConfig } from './Utilities/authConfig'
import { msalInstance } from './index'
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import { SignIn } from './Login/SignIn'
import { GetJSON } from './Utilities/APICall';

export function Main(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState();
    const [employeeId, setEmployeeId] = React.useState('');
    const [loginId, setLoginId] = React.useState('');
    const [role, setRole] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const { inProgress } = useMsal();
    const activeAccount = props.instance.getActiveAccount();
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (IsNullOrEmptyOrUndefined(activeAccount) === false && dataFetchedRef.current === false) {
            getUserInfo(activeAccount.username);
            dataFetchedRef.current = true;
        }
        const queryParams = new URLSearchParams(window.location.search);
        const queryReportId = queryParams.get("ReportId");
        if (IsNullOrEmptyOrUndefined(queryReportId) === false) {
            localStorage.setItem('QSReportId', queryReportId)
        }
    }, [activeAccount]);

    const getUserInfo = (LoginId) => {
        setLoginId(LoginId)
        setLoading(true)
        var url = BASE_URL + 'UserMaster/GetByLoginId';

        GetJSON(url, props.instance).then((data) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(data.error) === false) {
                CustomAlert(data.msg, 'error');
            }
            else {
                if (IsNullOrEmptyOrUndefined(data) === false) {
                    var item = { loginid: LoginId, userid: data.id, employeeid: data.employeeId, emailid: data.emailId, firstname: data.firstName, lastname: data.lastName, role: data.role }
                    setRole(data.role)
                    setFirstName(data.firstName);
                    setEmployeeId(data.employeeId)
                    dispatch({ type: "userinfo_login", userinfo: item });
                }
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e.message, 'error');
            });
    }

    return (
        <>

            <AuthenticatedTemplate>

                <Router>
                    {
                        loading &&
                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', position: 'absolute', minWidth: '100%', left: 0, top: '46%' }}><Fab disabled={true} style={{ backgroundColor: '#fff', boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(75, 22, 22, 0.12)' }}>{<CircularProgress size={35} style={{ color: '#1976d2' }} />}</Fab></div>
                    }
                    {
                        (loading === false && IsNullOrEmptyOrUndefined(firstName)) &&
                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', position: 'absolute', minWidth: '100%', left: 0, top: '35%' }}>
                            <Card variant="outlined" style={{ width: '70%' }}>
                                <CardHeader title='Welcome to Accelerator platform' sx={{ textAlign: 'left', padding: '10px', width: '100%' }} avatar={<Speed color="primary" />} />
                                <Divider />
                                <CardContent sx={{ textAlign: 'left' }}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        You are getting this message because of possible reasons. Please raise a case with the Application Support team to build and assign the report to your LoginId:{loginId}
                                    </Typography>
                                    <ul>
                                        <li>You are not authorized to view any report for your LoginId ({loginId}).</li>
                                        <li>Local Network is unable to connect to the application's service. </li>
                                    </ul>
                                </CardContent>
                            </Card>
                        </div>
                    }
                    <Layout instance={props.instance}>
                        <Routes>
                            {(IsNullOrEmptyOrUndefined(firstName) === false) && <>
                                <Route exact path='/' element={<Home instance={props.instance} />} />
                                <Route exact path='/Home' element={<Home instance={props.instance} />} />
                                <Route path="*" element={<Home instance={props.instance} />} /> // page-not-found route
                            </>
                            }
                            {role === 'Developer' && <>
                                <Route exact path='/ReportMaster' element={<ReportMaster instance={props.instance} />} />
                                <Route exact path='/UserMaster' element={<UserMaster instance={props.instance} />} />
                                <Route exact path='/ReportLookup' element={<ReportLookup instance={props.instance} />} />
                            </>
                            }
                        </Routes>
                    </Layout>
                </Router>

            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <SignIn instance={props.instance} />
            </UnauthenticatedTemplate>

            <ToastContainer />
        </>
    );
}
function App(props) {
    console.log('Environment API:' + process.env.REACT_APP_API_URL);
    return (
        <MsalProvider instance={props.instance}>
            <Main instance={props.instance} />
        </MsalProvider>
    )
}

export default App;
