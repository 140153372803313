import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { FirstLetters, IsNullOrEmptyOrUndefined } from '../Utilities/String';
import { CustomAlert } from '../Utilities/CustomAlert'
import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, FormGroup, Checkbox, Avatar, Box, IconButton, TextField, Button, Card, CardContent, CardHeader, Divider, Grid, Tooltip, Autocomplete, Alert } from '@mui/material';
import { BASE_URL } from '../Utilities/APIURLs';
import { DeleteOutline, Key } from '@mui/icons-material';
import { GetJSON, PostJSON } from '../Utilities/APICall';
import { useSelector } from 'react-redux'

export function ReportAccess(props) {
    const userinfo = useSelector((state) => state.userinfo);

    const dateformat = 'DD MMM YY HH:mm:ss';
    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [userNames, setUserNames] = React.useState([]);

    const [loginId, setLoginId] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [isUserEdit, setIsUserEdit] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState('');
    const [sendComms, setSendComms] = React.useState(false);
    const [anonymousAccess, setAnonymousAccess] = React.useState(false);
    const [disableControl, setDisableControl] = React.useState(false);
    const [role, setRole] = React.useState('Viewer');

    const ROLES = [{ name: 'Report viewer', value: 'Viewer' }, { name: 'Report owner', value: 'Owner' }]
    const COLORS = ['#ff8080', '#80aeff', '#82ff80', '#99cfff', '#ad7de8', '#ff80f0', '#75e6f0', '#33ffe7']


    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (props.AccessInfo.ReportId > 0 && dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            getAllUsers();
            getUserByReportId(props.AccessInfo.ReportId)
        }
    }, [props.AccessInfo.ReportId]);

    /** API Calls */
    const getAllUsers = () => {
        setAllUsers([])
        setLoading(true)
        var url = BASE_URL + 'UserMaster/GetActiveUsers';
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                let names = [];
                data.forEach(arr => names.push({ 'label': arr.name, 'id': arr.id }));
                setAllUsers(names);
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const getUserByReportId = (ReportId) => {
        setLoading(true)
        var url = BASE_URL + 'Access/Get?ReportId=' + ReportId
        GetJSON(url, props.instance)
            .then(data => {
                dataFetchedRef.current = false;
                setLoading(false)
                if (data.length > 0) {
                    var acc = data.filter((o) => o.userId === 0)
                    if (acc.length > 0) {
                        handleAnonymousAccess(true);
                    }
                    else {
                        handleAnonymousAccess(false);
                    }
                    var withoutAnonymous = data.filter((o) => o.userId !== 0)
                    setUsers(withoutAnonymous);
                }
                else {
                    setUsers([])
                }
            })
            .catch((e) => {
                dataFetchedRef.current = false;
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const saveReportUser = () => {
        if (anonymousAccess === false) {
            if (userNames.length <= 0) {
                CustomAlert('Please select User Name', 'error')
                return false;
            }
        }

        setLoading(true)
        var uid = 0
        if (anonymousAccess) uid = 0;
        else uid = userNames.map((filObj) => filObj.id).join(',');

        var datamodel = { 'Ids': uid, 'ReportId': props.AccessInfo.ReportId, 'ModifiedBy': userinfo.userid, 'Notification': sendComms, 'Role': role }
        var url = BASE_URL + 'Access/Insert'

        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)

                if (data.data.length > 0) {
                    var withoutAnonymous = data.data.filter((o) => o.userId !== 0)
                    setUsers(withoutAnonymous);
                }
                else {
                    setUsers([])
                }

                handleResetUser()
                if (IsNullOrEmptyOrUndefined(data.message) === false) CustomAlert(data.message, 'error')
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const deleteReportUser = (UserId, ReportId) => {
        setLoading(true)
        var url = BASE_URL + 'Access/Delete?UserId=' + UserId + '&ReportId=' + ReportId;
        GetJSON(url, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.length > 0) {
                    var withoutAnonymous = data.filter((o) => o.userId !== 0)
                    setUsers(withoutAnonymous);
                }
                else {
                    setUsers([])
                }
                CustomAlert('User has unassigned from the report', 'success')
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const deleteAnonymousUser = (ReportId) => {
        setLoading(true)
        var url = BASE_URL + 'Access/RemoveAnonymous?ReportId=' + ReportId + '&Role=' + role;
        GetJSON(url, props.instance)
            .then((data) => {
                setLoading(false)
                if (data === 'success')
                    CustomAlert('Anonymous access has been removed from the report', 'success')
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    /** API Calls End */

    const handleResetUser = () => {
        setUserNames([])
        setLoginId('')
        setIsUserEdit(false);
        setSelectedId('');
        setRole('Viewer');
    }
    const handleDeleteUser = (UserId) => {
        if (window.confirm("Are you sure you want to unassign the selected user?") === true) {
            deleteReportUser(UserId, props.AccessInfo.ReportId);
        }
    }
    const columnsUser = [
        { field: 'id', headerName: 'Id', },
        { field: 'userId', headerName: 'UserId' },
        {
            field: 'NameIcon', headerName: '', width: 40,
            renderCell: (cell) => {
                return (<><Avatar sx={{ marginTop: .3, color: 'black', fontSize: 11, width: 25, height: 25, bgcolor: COLORS[Math.floor(Math.random() * COLORS.length)] }}>{FirstLetters(cell.row.userName)}</Avatar></>);
            },
        },
        { field: 'userName', headerName: 'Name', width: 200, sortable: true },
        { field: 'loginId', headerName: 'Login Id', width: 280, sortable: true },
        { field: 'role', headerName: 'Role', flex: 1, sortable: true },
        {
            field: 'edit', headerName: '', minWidth: 60, width: 60,
            renderCell: (cell) => {
                return (
                    <>
                        <Tooltip title='Unassigned'><IconButton size='small' aria-label="unassigned" onClick={() => handleDeleteUser(cell.row.userId)}><DeleteOutline sx={{ fontSize: 22 }} color='error' /></IconButton></Tooltip>
                    </>
                );
            },
        },
    ]
    const handleUserName = (event, value) => {
        if (value.length > 0) {
            setUserNames(value);
        }
        else {
            setUserNames([])
        }
    }
    function handleNewUserClick(event) {
        navigate('/UserMaster');
    }
    const handleAnonymousAccess = (value) => {
        setDisableControl(value);
        setAnonymousAccess(value)
    }
    const handleSaveAnonymous = () => {
        if (anonymousAccess) {
            saveReportUser()
        }
        else {
            deleteAnonymousUser(props.AccessInfo.ReportId)
        }
    }
    return (
        <>

            <Card variant="outlined" style={{ height: window.innerHeight - 80 }}>
                <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title={<>{'Report Permission'} <span style={{ fontSize: 15, fontWeight: 550, paddingLeft: 4 }}>{': ' + props.AccessInfo.ReportName}</span></>} titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}><Key fontSize='small' /></Avatar>} />
                <Divider />
                {anonymousAccess && <Alert severity="success" color="warning" style={{ borderRadius: 0, paddingTop: 3, paddingBottom: 3 }}>This report has been accessed by all user by default.</Alert>}
                <CardContent>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }}>
                        <Grid item xs={6}>
                            <Card variant='outlined' style={{ pointerEvents: disableControl ? 'none' : '', opacity: disableControl ? 0.4 : '' }}>
                                <CardContent style={{ paddingBottom: 8, paddingTop: 8 }}>
                                    <Grid container spacing={1.1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                                        <Grid item xs={9}>
                                            <Autocomplete multiple id='acDeveloper' size='small' options={allUsers} value={userNames} onChange={(event, newInputValue) => { handleUserName(event, newInputValue) }} renderInput={(params) => (<TextField {...params} label="Select user/s" placeholder="Users" />)} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormGroup><FormControlLabel control={<Checkbox disabled={disableControl} checked={sendComms} />} label={'Notify'} onChange={(event) => setSendComms(event.target.checked)} /></FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1.1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: 5 }}>
                                        <Grid item xs={9}>
                                            <FormControl sx={{ width: '100%' }} size="small" required>
                                                <InputLabel style={{ fontSize: 14 }}>Role</InputLabel>
                                                <Select label="Role" style={{ fontSize: 14 }} value={role} onChange={(o) => setRole(o.target.value)}>
                                                    {
                                                        ROLES.map((name) => (<MenuItem key={name.value} value={name.value} style={{ fontSize: 13 }}>{name.name}</MenuItem>))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button variant="outlined" disableElevation color="primary" style={{ textTransform: 'none', width: 80, height: 37 }} onClick={saveReportUser}>{'Assign'}</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card variant='outlined' style={{ display: props.ShowGrantAll ? '' : 'none' }}>
                                <CardContent style={{ paddingBottom: 8, paddingTop: 8 }}>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                                        <Grid item style={{ height: 63 }}>
                                            <FormGroup><FormControlLabel control={<Checkbox checked={anonymousAccess} />} label={'Authorize all app users'} onChange={(o) => handleAnonymousAccess(o.target.checked)} /></FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                                        <Grid item xs={9}>
                                            <FormControl sx={{ width: '100%' }} size="small" required>
                                                <InputLabel style={{ fontSize: 14 }}>Role</InputLabel>
                                                <Select label="Role" style={{ fontSize: 14 }} value={role} onChange={(o) => setRole(o.target.value)}>
                                                    {
                                                        ROLES.map((name) => (<MenuItem key={name.value} value={name.value} style={{ fontSize: 13 }}>{name.name}</MenuItem>))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button variant="outlined" disableElevation color="primary" style={{ textTransform: 'none', width: 80, height: 35 }} onClick={handleSaveAnonymous}>{'Submit'}</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Box sx={{ height: window.innerHeight - 320, width: '100%' }}>
                        <DataGrid rowHeight={30} columnHeaderHeight={30} rows={users} columns={columnsUser} disableColumnMenu loading={loading}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10, }, },
                                columns: { columnVisibilityModel: { userId: false, id: false, }, },
                            }}
                            pageSizeOptions={[10]} disableRowSelectionOnClick
                            sx={{ fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                        />
                    </Box>
                </CardContent>
            </Card >
        </>
    );

}
